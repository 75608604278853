import React from "react";
import styles from "./index.module.scss";
import classnames from "classnames";
import WarningIcon from "../icons/Warning.svg";

export default ({ children, warning, appStyles = {} }) => (
  <div
    style={{ ...(warning ? appStyles.WarningBox : appStyles.ErrorBox) }}
    className={classnames(
      styles.ErrorMessage,
      warning && styles.WarningMessage,
    )}
  >
    <span>
      <WarningIcon className={styles.ErrorIcon} />
    </span>
    {children}
  </div>
);
