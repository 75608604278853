import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import * as storage from 'redux-storage';
import createEngine from 'redux-storage-engine-localstorage';
import debounce from 'redux-storage-decorator-debounce';
import createReducer from './index';

export default function configureStore(initialState = {}, options = {}) {
  const engine = debounce(createEngine(`AppFront_${process.env.GATSBY_BUSINESS_ID}`), 500);
  const localstorageMiddleware = storage.createMiddleware(engine);
  const middlewares = [thunk, localstorageMiddleware];

  const enhancers = [applyMiddleware(...middlewares)];

  const composeEnhancers =
    //process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
          // Prevent recomputing reducers for `replaceReducer`
          shouldHotReload: false,
        })
      : compose;
  /* eslint-enable */

  const store = createStore(createReducer(), initialState, composeEnhancers(...enhancers));

  // Extensions
  store.injectedReducers = {}; // Reducer registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./index', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }
  if (options.loadLocalStorage) {
    const load = storage.createLoader(engine);
    load(store);
  }
  return store;
}
