import React from "react";
import classnames from "classnames";
import UserAvatar from "../UserAvatar";
import EmptyProfileIcon from "../icons/EmptyProfile.svg";
import pick from "lodash/pick";
import compact from "lodash/compact";

import styles from "./HeaderUserProfileLink.module.scss";

const userGreeting = username => {
  if (!username) return "";
  const firstName = username
    .trim()
    .split(" ")[0]
    .trim();
  if (!firstName) return "";
  return firstName;
};

export default ({
  loggedIn,
  loginLabelText,
  loginLabelDesktopExtraText,
  showLabel,
  userDetails,
  T,
  onLoginClicked,
  onUserOpenAccountMenuClicked,
  amountOfGiftsUnseen,
  appStyles,
}) => {
  const onClick = () => {
    if (loggedIn) {
      onUserOpenAccountMenuClicked();
    } else {
      onLoginClicked();
    }
  };
  const userLoggedIn = loggedIn && userDetails && userDetails.data;
  const { HeaderLoginButton } = appStyles;
  const getLabelText = () => (
    <span key="profileGreeting">
      <span
        className={classnames(styles.Username, styles.HideOnDesktop)}
        style={appStyles.noIcons ? { margin: 0 } : {}}
      >
        {userLoggedIn
          ? `${T("Hi")} ${userGreeting(userDetails.data.name)}!`
          : loginLabelText}
      </span>

      <span
        className={classnames(
          styles.Username,
          styles.HideOnMobile,
          appStyles.rtl && styles.RTL,
        )}
        style={appStyles.noIcons ? { margin: 0 } : {}}
      >
        {userLoggedIn
          ? `${T("Hi")} ${userGreeting(userDetails.data.name)}!`
          : loginLabelText + loginLabelDesktopExtraText}
      </span>
    </span>
  );
  return (
    !userDetails.loading && (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          onClick={onClick}
          className={classnames(styles.user, appStyles.rtl && styles.RTL)}
          style={
            userLoggedIn
              ? { color: appStyles.headerTextColor }
              : {
                  color: "white",
                  backgroundColor: appStyles.actionColor,
                  ...pick(
                    appStyles.Button,
                    "color",
                    "background",
                    "backgroundColor",
                    "border",
                  ),
                  padding: showLabel ? "5px 10px" : 5,
                  borderRadius: 50,
                  ...HeaderLoginButton,
                }
          }
          role="button"
          aria-label={userLoggedIn ? T("My Account") : T("Login")}
        >
          {showLabel && getLabelText()}
          {userLoggedIn ? (
            <UserAvatar
              name={userDetails.data.name}
              avatarUrl={
                userDetails.data.avatarImage && userDetails.data.avatarImage.url
              }
              amountOfGiftsUnseen={amountOfGiftsUnseen}
              appStyles={appStyles}
            />
          ) : (
            compact([
              !showLabel && appStyles.noIcons && getLabelText(),
              !appStyles.noIcons && <EmptyProfileIcon key="emptyProfile" />,
            ])
          )}
        </div>
      </div>
    )
  );
};
