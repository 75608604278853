import { get } from "lodash";
import { getAppStyles } from "./app-styles";

export const getPromotions = (state, props) => {
  const homepagePromotions = get(
    getAppStyles(state, props),
    "homepagePromotions",
  );
  const promotions = get(props, "pageContext.promotions");
  const editorPromotions = get(state, "app.editorPromotions");

  return editorPromotions || promotions || {};
};
