import _ from "lodash";
import moment from "./moment-timezone-with-data-2012-2022";
import addDays from "date-fns/add_days";
import addMonths from "date-fns/add_months";
import getDay from "date-fns/get_day";
import localeDates from "./localeDates";
import { AVAILABLE_NOW, NOW } from "./constants";

export const INDEX_TO_WEEKDAY = {
  0: "SUNDAY",
  1: "MONDAY",
  2: "TUESDAY",
  3: "WEDNESDAY",
  4: "THURSDAY",
  5: "FRIDAY",
  6: "SATURDAY",
};

export const WEEKDAY_TO_INDEX = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

export const isOverNightSession = (timeFrame) =>
  timeFrame.endHour < timeFrame.startHour;

export const getStartTime = (timeFrame, timeZoneStr, date) =>
  moment
    .tz(date, timeZoneStr)
    .hours(timeFrame.startHour)
    .minutes(timeFrame.startMinute)
    .seconds(0);

export const getEndTime = (timeFrame, timeZoneStr, date) =>
  moment
    .tz(date, timeZoneStr)
    .hours(timeFrame.endHour)
    .minutes(timeFrame.endMinute)
    .seconds(0);

const getFirstAvailableDay = (
  start,
  branchClosedDays,
  timeZoneStr,
  afterStart,
) => {
  const WEEKDAYS = _.range(7);
  const partitionedDates = afterStart
    ? _.partition(
      _.difference(WEEKDAYS, branchClosedDays),
      (day) => day <= getDay(start),
    )
    : _.partition(
      _.difference(WEEKDAYS, branchClosedDays),
      (day) => day < getDay(start),
    );
  return _.isEmpty(partitionedDates[1])
    ? moment
      .tz(moment(start), timeZoneStr)
      .add(WEEKDAYS.length - getDay(start) + partitionedDates[0][0], "days")
    : moment
      .tz(moment(start), timeZoneStr)
      .add(partitionedDates[1][0] - getDay(start), "days");
};

export const getFirstAvailableDayAfterToday = (
  start,
  branchClosedDays,
  timeZoneStr,
) => getFirstAvailableDay(start, branchClosedDays, timeZoneStr, true);

const getFutureOrderIntervals = ({
  selectedServingOption,
  branchesAvailability,
}) =>
  _.reduce(
    branchesAvailability,
    (futureOrderIntervals, branchAvailability) => {
      const futureOrderInterval =
        _.get(
          branchAvailability,
          `servingOptionTypeToFutureOrderBuffer.${selectedServingOption.type}`,
        ) || selectedServingOption.futureOrderBuffer;

      const timeZoneStr = _.get(branchAvailability, "branch.timeZoneStr");
      if (!timeZoneStr) {
        console.error(
          `In getFutureOrderIntervals: No timeZoneStr set up for branch ${_.get(
            branchAvailability,
            "branch.name",
          )}`,
        );
      } else {
        const today = moment.tz(timeZoneStr).toDate();

        futureOrderIntervals[branchAvailability.branchId] = futureOrderInterval
          ? {
            before: addDays(today, futureOrderInterval.start),
            after: addDays(today, futureOrderInterval.end),
          }
          : {
            before: today,
            after: addMonths(today, 1),
          };
      }
      return futureOrderIntervals;
    },
    {},
  );

// TODO: filter empty time frames
const getClosedDaysFromTimeFrame = (timeFrames) =>
  _.difference(_.range(7), _.map(timeFrames, (timeFrame) => timeFrame.day));

const getBranchesClosedDays = ({
  branchesAvailability,
  timeFrames: servingOptionsTimeFrames,
  deliveryOptions,
}) => {
  const closedDays = _.reduce(
    branchesAvailability,
    (branchClosedDays, branch) => {
      branchClosedDays[branch.branchId] = [];

      if (branch.detailedOpenHours) {
        const openHours = branch.detailedOpenHours.openHours;

        const branchClosedDaysFromOpenHours = _.map(
          _.filter(openHours, {
            startHour: 0,
            startMinute: 0,
            endHour: 0,
            endMinute: 0,
          }),
          "day",
        );

        branchClosedDays[branch.branchId] = branchClosedDaysFromOpenHours;
      }

      if (servingOptionsTimeFrames) {
        const branchClosedDaysForServingOption = getClosedDaysFromTimeFrame(
          servingOptionsTimeFrames,
        );
        branchClosedDays[branch.branchId] = _.merge(
          branchClosedDays[branch.branchId],
          branchClosedDaysForServingOption,
        );
      }
      if (deliveryOptions) {
        const deliveryOptionForBranch = _.find(deliveryOptions, {
          branchId: branch.branchId,
        });
        if (deliveryOptionForBranch) {
          const branchClosedDaysForDelivery = getClosedDaysFromTimeFrame(
            deliveryOptionForBranch.timeFrames,
          );
          branchClosedDays[branch.branchId] = _.merge(
            branchClosedDays[branch.branchId],
            branchClosedDaysForDelivery,
          );
        }
      }
      branchClosedDays[branch.branchId] = _.uniq(
        branchClosedDays[branch.branchId],
      );
      return branchClosedDays;
    },
    {},
  );
  return closedDays;
};

const isServingOptionTypeToSpecificDelayedPickupTimesClosed = (
  timeString,
  timeZoneStr,
  day,
  today,
) => {
  const [hour, minutes] = timeString.split(":");
  if (hour && minutes) {
    const parsedTime = moment
      .tz(moment(day), timeZoneStr)
      .hours(hour)
      .minutes(minutes)
      .seconds(0);

    return parsedTime.isSameOrBefore(today);
  }
  return false;
};

export const getTimeFrameIntersectWithTimeGiven = (
  timeFrames,
  time,
  timeZoneStr,
) =>
  _.find(timeFrames, (timeFrame) => {
    const startTime = getStartTime(timeFrame, timeZoneStr, time);
    const endTime = getEndTime(timeFrame, timeZoneStr, time);

    return time.isSameOrAfter(startTime) && time.isSameOrBefore(endTime);
  });

export const getTimeFrameAfterTimeGiven = (timeFrames, time, timeZoneStr) =>
  _.minBy(
    _.filter(timeFrames, (timeFrame) => {
      const startTime = getStartTime(timeFrame, timeZoneStr, time);
      const endTime = getEndTime(timeFrame, timeZoneStr, time);

      return time.isBefore(startTime) && time.isBefore(endTime);
    }),
    ["startHour", "startMinute"],
  );

export const getTimeFrameIntersection = (
  openHoursTimeFrame,
  servingOptionTimeFrame,
  timeZoneStr,
) => {
  if (_.isEmpty(servingOptionTimeFrame)) {
    return null;
  }
  const timeFrame = _.cloneDeep(openHoursTimeFrame);
  const openHoursStartTime = getStartTime(openHoursTimeFrame, timeZoneStr);
  const openHoursEndTime = getEndTime(openHoursTimeFrame, timeZoneStr);
  const servingOptionStartTime = getStartTime(
    servingOptionTimeFrame,
    timeZoneStr,
  );
  const servingOptionEndTime = getEndTime(servingOptionTimeFrame, timeZoneStr);

  if (openHoursStartTime.isBefore(servingOptionStartTime)) {
    timeFrame.startHour = servingOptionTimeFrame.startHour;
    timeFrame.startMinute = servingOptionTimeFrame.startMinute;
  }
  if (openHoursEndTime.isAfter(servingOptionEndTime)) {
    timeFrame.endHour = servingOptionTimeFrame.endHour;
    timeFrame.endMinute = servingOptionTimeFrame.endMinute;
  }
  return timeFrame;
};

const getBranchesFirstAvailableDate = ({
  branchesAvailability,
  deliveryOptions,
  futureOrderIntervals: futureOrderIntervalsByLocations,
  branchesClosedDays: closedDaysByLocations,
  selectedServingOption,
}) =>
  _.reduce(
    branchesAvailability,
    (firstAvailableDatesByLocations, branchAvailability) => {
      const timeZoneStr = _.get(branchAvailability, "branch.timeZoneStr");
      const branchId = branchAvailability.branchId;

      if (!timeZoneStr) {
        console.error(
          `In getFirstAvailableDateByLocation: No timeZoneStr set up for branch ${_.get(
            branchAvailability,
            "branch.name",
          )}`,
        );
      } else {
        const now = moment.tz(timeZoneStr);

        const firstAvailableDate = _.get(
          branchAvailability,
          "branch.disableFutureOrders",
        )
          ? moment(_.get(
            branchAvailability,"availableFrom"))
          : getFirstAvailableDay(
            futureOrderIntervalsByLocations[branchId].before,
            closedDaysByLocations[branchId],
            timeZoneStr,
          );
        // case serving option is closed for the day or open later today
        if (firstAvailableDate.dayOfYear() === now.dayOfYear()) {
          const deliveryOptionForBranch = _.find(deliveryOptions, { branchId });

          const servingOptionTimeFrames = _.filter(
            selectedServingOption.needsAddress
              ? deliveryOptionForBranch
                ? deliveryOptionForBranch.timeFrames
                : []
              : selectedServingOption.timeFrames,
            { day: firstAvailableDate.day() },
          );

          const openHoursTimeFrames = _.filter(
            _.get(branchAvailability, "detailedOpenHours.openHours"),
            { day: firstAvailableDate.day() },
          );
          const openHoursTimeFrameIntersectedWithNow = getTimeFrameIntersectWithTimeGiven(
            openHoursTimeFrames,
            now,
            timeZoneStr,
          );

          if (_.isEmpty(openHoursTimeFrameIntersectedWithNow)) {
            const hasOpenHourTimeFrameLaterToday = getTimeFrameAfterTimeGiven(
              openHoursTimeFrames,
              now,
              timeZoneStr,
            );
            if (hasOpenHourTimeFrameLaterToday) {
              firstAvailableDatesByLocations[
                branchId
              ] = firstAvailableDate.toDate();
            } else {
              firstAvailableDatesByLocations[
                branchId
              ] = getFirstAvailableDayAfterToday(
                firstAvailableDate.toDate(),
                closedDaysByLocations[branchId],
                timeZoneStr,
              ).toDate();
            }
            return firstAvailableDatesByLocations;
          }

          if (_.isEmpty(servingOptionTimeFrames)) {
            firstAvailableDatesByLocations[
              branchId
            ] = firstAvailableDate.toDate();
            return firstAvailableDatesByLocations;
          }

          const servingOptionTimeFrameIntersectedWithNow = getTimeFrameIntersectWithTimeGiven(
            servingOptionTimeFrames,
            now,
            timeZoneStr,
          );

          if (_.isEmpty(servingOptionTimeFrameIntersectedWithNow)) {
            const hasServingOptionTimeFrameLaterToday = getTimeFrameAfterTimeGiven(
              servingOptionTimeFrames,
              now,
              timeZoneStr,
            );
            if (hasServingOptionTimeFrameLaterToday) {
              firstAvailableDatesByLocations[
                branchId
              ] = firstAvailableDate.toDate();
            } else {
              const timeFrame = getTimeFrameIntersection(
                openHoursTimeFrameIntersectedWithNow,
                servingOptionTimeFrameIntersectedWithNow,
              );
              if (
                !timeFrame ||
                moment(firstAvailableDate)
                  .hours(timeFrame.endHour)
                  .minutes(timeFrame.endMinute)
                  .seconds(0)
                  .isBefore(now)
              ) {
                firstAvailableDatesByLocations[
                  branchId
                ] = getFirstAvailableDayAfterToday(
                  firstAvailableDate.toDate(),
                  closedDaysByLocations[branchId],
                  timeZoneStr,
                ).toDate();
                return firstAvailableDatesByLocations;
              }
            }
          }
        }
        firstAvailableDatesByLocations[branchId] = firstAvailableDate.toDate();
      }
      return firstAvailableDatesByLocations;
    },
    {},
  );

const getAllTimeFramesForDate = (timeFrames, date, timeZoneStr) => {
  const now = moment.tz(timeZoneStr);
  const timeFramesForDay = _.filter(timeFrames, { day: date.day() });
  const overnightSessionFromPreviousDay = _.find(timeFramesForDay, {
    isOverNightSession: true,
  });
  if (overnightSessionFromPreviousDay) {
    const overnightStartTime = getStartTime(
      overnightSessionFromPreviousDay,
      timeZoneStr,
      date,
    );
    const overnightEndTime = getEndTime(
      overnightSessionFromPreviousDay,
      timeZoneStr,
      date,
    );
    const isNowInPreviousDayOvernightSession =
      now.isSameOrAfter(overnightStartTime) &&
      now.isSameOrBefore(overnightEndTime);
    if (isNowInPreviousDayOvernightSession) {
      return [overnightSessionFromPreviousDay];
    }
  }
  const timeFramesForDayWithoutOvernight = _.filter(
    timeFrames,
    (timeFrame) =>
      timeFrame.day === date.day() && !timeFrame.isOverNightSession,
  );
  if (
    _.find(
      timeFramesForDayWithoutOvernight,
      (timeFrame) => timeFrame.hasOvernightSession,
    )
  ) {
    const overnightSessionTimeFrames = _.filter(timeFrames, (timeFrame) => {
      return (
        timeFrame.day === (date.day() + 1) % 7 && timeFrame.isOverNightSession
      );
    });
    return _.concat(
      timeFramesForDayWithoutOvernight,
      overnightSessionTimeFrames,
    );
  }
  return timeFramesForDayWithoutOvernight;
};

const getTimeFrameIntervals = ({
  day,
  timeZoneStr,
  openHoursTimeFrames,
  servingOptionTimeFrames,
}) => {
  const openHourTimeFramesForDate = getAllTimeFramesForDate(
    openHoursTimeFrames,
    day,
    timeZoneStr,
  );
  if (_.isEmpty(openHourTimeFramesForDate)) {
    return [];
  }
  const servingOptionTimeFramesForDate = getAllTimeFramesForDate(
    servingOptionTimeFrames,
    day,
    timeZoneStr,
  );
  if (_.isEmpty(servingOptionTimeFramesForDate)) {
    return openHourTimeFramesForDate;
  }
  const timeFrameIntervals = [];
  _.forEach(openHourTimeFramesForDate, (openHourTimeFrame) => {
    const openHourStartTime = getStartTime(openHourTimeFrame, timeZoneStr, day);
    const openHourEndTime = getEndTime(openHourTimeFrame, timeZoneStr, day);
    _.forEach(servingOptionTimeFramesForDate, (servingOptionTimeFrame) => {
      const servingOptionStartTime = getStartTime(
        servingOptionTimeFrame,
        timeZoneStr,
        day,
      );
      const servingOptionEndTime = getEndTime(
        servingOptionTimeFrame,
        timeZoneStr,
        day,
      );
      if (
        openHourStartTime.isSameOrAfter(servingOptionStartTime) &&
        openHourEndTime.isSameOrBefore(servingOptionEndTime)
      ) {
        timeFrameIntervals.push(openHourTimeFrame);
      } else if (
        servingOptionStartTime.isSameOrAfter(openHourStartTime) &&
        servingOptionEndTime.isSameOrBefore(openHourEndTime)
      ) {
        timeFrameIntervals.push(servingOptionTimeFrame);
      } else if (
        openHourStartTime.isSameOrBefore(servingOptionStartTime) &&
        openHourEndTime.isAfter(servingOptionStartTime)
      ) {
        timeFrameIntervals.push({
          startHour: servingOptionTimeFrame.startHour,
          startMinute: servingOptionTimeFrame.startMinute,
          endHour: openHourTimeFrame.endHour,
          endMinute: openHourTimeFrame.endMinute,
          day: day.day(),
        });
      } else if (
        openHourStartTime.isSameOrAfter(servingOptionStartTime) &&
        openHourStartTime.isBefore(servingOptionEndTime)
      ) {
        timeFrameIntervals.push({
          startHour: openHourTimeFrame.startHour,
          startMinute: openHourTimeFrame.startMinute,
          endHour: servingOptionTimeFrame.endHour,
          endMinute: servingOptionTimeFrame.endMinute,
          day: day.day(),
        });
      }
    });
  });
  return timeFrameIntervals;
};

export const getFutureOrderAvailability = ({
  selectedServingOption,
  branchesAvailability,
  branchId = null,
  deliveryOptions,
}) => {
  if (
    !selectedServingOption.enableFutureOrders ||
    _.isEmpty(branchesAvailability)
  ) {
    return {
      firstAvailableDates: _.reduce(
        branchesAvailability,
        (firstAvailableDates, branchAvailability) => {
          const timeZoneStr = _.get(branchAvailability, "branch.timeZoneStr");
          if (!timeZoneStr) {
            console.error(
              `In getFutureOrderAvailability: No timeZoneStr set up for branch ${_.get(
                branchAvailability,
                "branch.name",
              )}`,
            );
          } else {
            firstAvailableDates[branchAvailability.branchId] = moment
              .tz(timeZoneStr)
              .toDate();
          }
          return firstAvailableDates;
        },
        {},
      ),
      futureOrderIntervals: {},
      branchesClosedDays: _.reduce(
        branchesAvailability,
        (acc, branchAvailability) => {
          acc[branchAvailability.branchId] = _.map(
            _.filter(branchAvailability.detailedOpenHours.openHours, {
              startHour: 0,
              startMinute: 0,
              endHour: 0,
              endMinute: 0,
            }),
            "day",
          );
          return acc;
        },
        {},
      ),
    };
  }

  const futureOrderIntervals = getFutureOrderIntervals({
    selectedServingOption,
    branchesAvailability,
  });

  const branchesClosedDays = getBranchesClosedDays({
    branchesAvailability,
    ...(selectedServingOption.needsAddress
      ? { deliveryOptions }
      : { timeFrames: selectedServingOption.timeFrames }),
  });

  const firstAvailableDates = getBranchesFirstAvailableDate({
    branchesAvailability,
    deliveryOptions,
    futureOrderIntervals,
    branchesClosedDays,
    selectedServingOption,
  });

  if (branchId) {
    return {
      futureOrderInterval: futureOrderIntervals[branchId],
      branchClosedDays: branchesClosedDays[branchId],
      firstAvailableDate: firstAvailableDates[branchId],
    };
  }
  return { futureOrderIntervals, branchesClosedDays, firstAvailableDates };
};

const getHour = (hour) =>
  _.map(_.split(hour, ":"), (time) => _.toInteger(time));

const isThrottledTime = (time, branchAvailability) => {
  //check if in filtered throttling times:
  const throttlingFilteredTimeFrames = _.get(
    branchAvailability, "throttlingFilteredTimeFrames");
  if (throttlingFilteredTimeFrames) {
    const filteredTimes = _.filter(throttlingFilteredTimeFrames, filteredTimeFrame => {
      return _.get(filteredTimeFrame, "fromDate") &&
        _.get(filteredTimeFrame, "toDate") &&
        time.isSameOrAfter(moment(_.get(filteredTimeFrame, "fromDate")).subtract(1, 'minutes')) &&
        time.isSameOrBefore(moment(_.get(filteredTimeFrame, "toDate")).subtract(1, 'minutes'));
    });
    if (!_.isEmpty(filteredTimes)){
      return true;
    }
  }
  return false;
}

export const getPickupTimes = ({
  timeZoneStr,
  selectedServingOptionType,
  branchAvailability,
  delayedOrderInterval,
  openHoursTimeFrames,
  servingOptionTimeFrames,
  day,
  servingDelay,
  locale,
  disableServingNow,
  overallServingTime,
  orderForToday,
}) => {
  const timeFrameIntervals = getTimeFrameIntervals({
    day,
    timeZoneStr,
    openHoursTimeFrames,
    servingOptionTimeFrames,
  });

  const pickupTimes = _.flatMap(
    _.map(timeFrameIntervals, (timeFrameInterval) => {
      const {
        startHour,
        startMinute,
        endHour,
        endMinute,
        isOverNightSession,
      } = timeFrameInterval;

      const start = moment
        .tz(day, timeZoneStr)
        .hours(startHour)
        .minutes(startMinute)
        .seconds(0);

      const end = moment
        .tz(day, timeZoneStr)
        .hours(endHour)
        .minutes(endMinute)
        .seconds(0);

      const range = moment.range(start, end);
      const nowPlusPrepTime = moment
        .tz(timeZoneStr)
        .add(overallServingTime, "minutes");

      return _.get(
        branchAvailability,
        `servingOptionTypeToSpecificDelayedPickupTimes.${selectedServingOptionType}`,
      )
        ? _.map(
          _.filter(
            _.map(
              _.split(
                _.get(
                  branchAvailability,
                  `servingOptionTypeToSpecificDelayedPickupTimes.${selectedServingOptionType}`,
                ),
                ",",
              ),
              (timeString) => {
                const [hour, minutes] = timeString.split(":");
                if (hour && minutes) {
                  const parsedTime = moment
                    .tz(day, timeZoneStr)
                    .hours(hour)
                    .minutes(minutes)
                    .seconds(0);

                  return parsedTime;
                }
                return undefined;
              },
            ),
            (timeDate) => {
              if (timeDate) {
                if (isThrottledTime(timeDate, branchAvailability)) {
                  return false;
                }

                return timeDate.isSameOrAfter(start) &&
                  timeDate.isSameOrAfter(nowPlusPrepTime) &&
                  timeDate.isSameOrBefore(end)
                  ? timeDate
                  : false;
              }
              return false;
            },
          ),
          (time) => time.format(localeDates[locale || "en-US"].hourFormat),
        )
        : _.map(
          _.filter(
            Array.from(range.by("minute", { step: delayedOrderInterval })),
            (time) => time.isSameOrAfter(nowPlusPrepTime) && !isThrottledTime(time, branchAvailability)
          ),
          (time) => time.format(localeDates[locale || "en-US"].hourFormat)
        );
    }),
  );

  return {
    loading: false,
    error: null,
    status: branchAvailability.availability,
    firstPickupTimeDelay: overallServingTime,
    data: 
      _.concat((branchAvailability.availability === AVAILABLE_NOW &&
        !disableServingNow &&
        (_.isEmpty(pickupTimes) ||
          (!_.isEmpty(pickupTimes) && orderForToday && !disableServingNow)))
        ? [NOW] : [], (pickupTimes ? pickupTimes : [])),
    
  };
};

const isTimeFrameMatch = (
  openHourTimeFrame,
  servingOptionTimeFrame,
  date,
  timeZoneStr,
) => {
  const openHoursStartTime = getStartTime(openHourTimeFrame, timeZoneStr, date);
  const openHoursEndTime = getEndTime(openHourTimeFrame, timeZoneStr, date);
  if (openHoursEndTime && openHoursEndTime.isBefore(date)){
    //this time frame has already passed
    return false;
  }
  const servingOptionStartTime = getStartTime(
    servingOptionTimeFrame,
    timeZoneStr,
    date,
  );
  const servingOptionEndTime = getEndTime(
    servingOptionTimeFrame,
    timeZoneStr,
    date,
  );

  if (
    openHoursStartTime.isSameOrBefore(servingOptionStartTime) &&
    openHoursEndTime.isSameOrAfter(servingOptionEndTime)
  ) {
    return true;
  }
  if (
    openHoursStartTime.isSameOrAfter(servingOptionStartTime) &&
    openHoursEndTime.isSameOrBefore(servingOptionEndTime)
  ) {
    return true;
  }
  if (
    openHoursStartTime.isSameOrBefore(servingOptionStartTime) &&
    openHoursEndTime.isAfter(servingOptionStartTime)
  ) {
    return true;
  }
  if (
    openHoursStartTime.isSameOrAfter(servingOptionStartTime) &&
    openHoursStartTime.isBefore(servingOptionEndTime)
  ) {
    return true;
  }
  return false;
};

const filterOpenTimeFrames = (timeFrames) =>
  _.filter(
    timeFrames,
    (timeFrame) =>
      timeFrame.startHour !== 0 ||
      timeFrame.endHour !== 0 ||
      timeFrame.startMinute !== 0 ||
      timeFrame.endMinute !== 0,
  );

// TODO: change this
const getNextAvailableTimeFrame = ({
  firstAvailableDate,
  openHoursTimeFrames,
  servingOptionTimeFrames,
  timeZoneStr,
}) => {
  if (_.isEmpty(openHoursTimeFrames)) {
    return null;
  }
  if (_.isEmpty(servingOptionTimeFrames)) {
    return openHoursTimeFrames[0];
  }

  let chosenServingOptionTimeFrame;

  const chosenOpenHourTimeFrame = _.find(
    openHoursTimeFrames,
    (openHoursTimeFrame) => {
      chosenServingOptionTimeFrame = _.find(
        servingOptionTimeFrames,
        (servingOptionTimeFrame) => {
          if (
            isTimeFrameMatch(
              openHoursTimeFrame,
              servingOptionTimeFrame,
              firstAvailableDate,
              timeZoneStr,
            )
          ) {
            return true;
          }
          return false;
        },
      );
      return !_.isEmpty(chosenServingOptionTimeFrame);
    },
  );

  if (_.isEmpty(chosenOpenHourTimeFrame)) {
    return null;
  }

  const nextAvailableTimeFrame = {};
  if (
    chosenOpenHourTimeFrame.startHour > chosenServingOptionTimeFrame.startHour
  ) {
    nextAvailableTimeFrame.startHour = chosenOpenHourTimeFrame.startHour;
    nextAvailableTimeFrame.startMinute = chosenOpenHourTimeFrame.startMinute;
  } else if (
    chosenOpenHourTimeFrame.startHour < chosenServingOptionTimeFrame.startHour
  ) {
    nextAvailableTimeFrame.startHour = chosenServingOptionTimeFrame.startHour;
    nextAvailableTimeFrame.startMinute =
      chosenServingOptionTimeFrame.startMinute;
  } else {
    if (
      chosenOpenHourTimeFrame.startMinute >=
      chosenServingOptionTimeFrame.startMinute
    ) {
      nextAvailableTimeFrame.startHour = chosenOpenHourTimeFrame.startHour;
      nextAvailableTimeFrame.startMinute = chosenOpenHourTimeFrame.startMinute;
    } else {
      nextAvailableTimeFrame.startHour = chosenServingOptionTimeFrame.startHour;
      nextAvailableTimeFrame.startMinute =
        chosenServingOptionTimeFrame.startMinute;
    }
  }
  return nextAvailableTimeFrame;
};

export const getAvailableFrom = ({
  firstAvailableDate,
  openHoursTimeFrames,
  servingOptionTimeFrames,
  timeZoneStr,
}) => {
  const sortedOpenHoursTimeFrames = _.sortBy(
    filterOpenTimeFrames(openHoursTimeFrames),
    ["startHour", "startMinute"],
  );
  const sortedServingOptionTimeFrames = _.sortBy(
    filterOpenTimeFrames(servingOptionTimeFrames),
    ["startHour", "startMinute"],
  );

  const nextAvailableTimeFrame = getNextAvailableTimeFrame({
    firstAvailableDate,
    openHoursTimeFrames: sortedOpenHoursTimeFrames,
    servingOptionTimeFrames: sortedServingOptionTimeFrames,
    timeZoneStr,
  });

  return nextAvailableTimeFrame
    ? moment
      .tz(firstAvailableDate, timeZoneStr)
      .hours(nextAvailableTimeFrame.startHour)
      .minute(nextAvailableTimeFrame.startMinute)
      .seconds(0)
      .toDate()
    : null;
};
