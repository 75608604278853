import React from "react";
import sumBy from "lodash/sumBy";
import get from "lodash/get";
import classnames from "classnames";
import styles from "../../layouts/CheckoutSideBar.module.scss";
import BagItemsIndicator from "../BagItemsIndicator";
import CloseIcon from "../icons/Close.svg";

export default ({ T, order, toggleCheckoutView, appStyles, checkoutOpen }) => (
  <span
    className={classnames(
      styles.CheckoutPanelHeader,
      checkoutOpen && styles.Open,
      appStyles.rtl && styles.RTL,
    )}
    style={{
      background: checkoutOpen
        ? appStyles.backgroundColor
        : get(appStyles, "Header.backgroundImage")
        ? "rgba(0,0,0,0)"
        : appStyles.headerBackgroundColor,
    }}
  >
    <span
      onClick={toggleCheckoutView}
      className={classnames(
        styles.CheckoutHeaderBugButton,
        checkoutOpen && styles.Open,
        appStyles.rtl && styles.RTL,
      )}
      style={{
        color: checkoutOpen ? appStyles.accentColor : appStyles.headerTextColor,
      }}
    >
      <BagItemsIndicator
        appStyles={appStyles}
        counter={sumBy(
          order.orderItems,
          item => item.configuredMenuItemOrderData.count || 1,
        )}
      />
    </span>
    {checkoutOpen && (
      <span
        className={styles.CheckoutHeaderTitle}
        style={{
          ...appStyles.Header,
          color: appStyles.textColor,
          background: "transparent",
        }}
      >
        {T("Order")}
      </span>
    )}
    <span
      onClick={toggleCheckoutView}
      className={classnames(
        styles.CheckoutHeaderCloseButton,
        checkoutOpen && styles.Open,
        appStyles.rtl && styles.RTL,
      )}
      style={{
        color: checkoutOpen ? appStyles.textColor : appStyles.headerTextColor,
      }}
      role="button"
      aria-label={T("Close")}
    >
      <CloseIcon />
    </span>
  </span>
);
