import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function ExpandMore({ expanded, handleExpandClick }) {
  const classes = useStyles();
  return (
    <IconButton
      className={clsx(classes.expand, {
        [classes.expandOpen]: expanded,
      })}
      onClick={handleExpandClick}
      aria-expanded={expanded}
      aria-label="show more"
      size="small"
    >
      <ExpandMoreIcon />
    </IconButton>
  );
}

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("all", {
      duration: theme.transitions.duration.complex,
    }),
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
  },
  expandOpen: {
    // backgroundColor: "transparent",
    // color: theme.palette.action.active,
    // "&:hover": {
    //   // color: theme.palette.action.active,
    //   // backgroundColor: "transparent",
    // },
    transform: "rotate(180deg)",
  },
}));
