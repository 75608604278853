import React from "react";
import _ from "lodash";
import styles from "./index.module.scss";
import classnames from "classnames";

export default ({
  children,
  primaryColor,
  borderRadius,
  big,
  tiles,
  centered,
  withMargin,
  round,
  appStyles = {},
  classNames,
  style,
}) => {
  const childrenButtons = children.map ? children : [children];
  const { Button } = appStyles;

  return (
    <div
      className={classnames(
        styles.ButtonGroup,
        big && styles.Big,
        centered && styles.Centered,
        withMargin && styles.WithMargin,
        tiles && styles.Tiles,
        classNames,
      )}
    >
      {_.map(_.filter(childrenButtons), child => ({
        ...child,
        props: {
          style: {
            ...Button,
            ...child.props.style,
            textAlign: "center",
            ...style,
          },
          ..._.omit(child.props, ["style"]),
          ...(round && { round: true }),
        },
      }))}
    </div>
  );
};
