import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import cn from "classnames";
import MobileDetect from "mobile-detect";
import React, { useEffect, useMemo, useState } from "react";

import ErrorXIcon from "../../icons/ErrorX.svg";

import styles from "./index.module.scss";

const useStyles = makeStyles((theme) => ({
  root: ({ inputValidColor }) => ({
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: inputValidColor,
      },
    },
    "& .MuiFormHelperText-root.Mui-error": {
      margin: 0,
    },
  }),
  input: ({ inputBorderRadius, inputOnCardBackgroundColor }) => ({
    borderRadius: inputBorderRadius,
    height: "52px",
    backgroundColor: inputOnCardBackgroundColor,
  }),
  mobileMultipleSelect: () => ({
    height: "auto",
  }),
  left: () => ({
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  }),
  middle: () => ({ borderRadius: 0 }),
  right: () => ({
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  }),
  selected: ({ inputValidColor }) => ({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: inputValidColor,
      },
    },
    "& label": {
      color: inputValidColor,
    },
  }),
  error: () => ({ backgroundColor: "#fae7e7" }),
  notSelected: () => ({
    "& .MuiSelect-selectMenu": { color: "#757575" },
  }),
}));

function SelectInput({
  groupPosition,
  onChange,
  options,
  value,
  placeholder,
  label,
  style,
  appStyles,
  noCheckmark,
  isError,
  errorMessage,
  multiple,
  customIconAdornment,
  ...props
}) {
  const classes = useStyles(appStyles);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const md = new MobileDetect(window.navigator.userAgent);
    setIsMobile(Boolean(md && md.mobile()));
  }, []);

  const [selectValue, setSelectValue] = useState(multiple ? [""] : "");
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (!value || (!value.length && multiple)) {
      setSelectValue(multiple ? [""] : "");
      setIsSelected(false);
      return;
    }

    if (!multiple) {
      setSelectValue(value);
      setIsSelected(true);
      return;
    }

    setSelectValue(value.filter(Boolean));
  }, [value, multiple]);

  const optionItems = options.length
    ? options.map(({ label, value }) =>
        isMobile ? (
          <option
            key={label + value}
            value={value}
            dir={appStyles.rtl ? "rtl" : "ltr"}
          >
            {label}
          </option>
        ) : (
          <MenuItem
            key={label + value}
            value={value}
            dir={appStyles.rtl ? "rtl" : "ltr"}
          >
            {label}
          </MenuItem>
        )
      )
    : null;

  const showCheckmarkIcon = useMemo(() => !noCheckmark && isSelected, [
    noCheckmark,
    isSelected,
  ]);

  const iconAdornment = useMemo(
    () =>
      showCheckmarkIcon ? (
        <InputAdornment position="start">
          <CheckCircle style={{ color: appStyles.inputValidColor }} />
        </InputAdornment>
      ) : (
        customIconAdornment || null
      ),
    [appStyles.inputValidColor, showCheckmarkIcon, customIconAdornment]
  );

  const errorMessageComponent = useMemo(
    () =>
      isError &&
      errorMessage && (
        <FormHelperText
          className={styles.errorAnimation}
          error={isError}
          id="component-error-text"
        >
          <ErrorXIcon className="noselect" style={{ marginRight: 4 }} />
          {errorMessage}
        </FormHelperText>
      ),
    [isError, errorMessage]
  );

  const handleChangeDesktop = (event) => {
    setIsSelected(true);
    const value = multiple
      ? event.target.value.filter(Boolean)
      : event.target.value;
    onChange(value);
  };

  const desktopContent = (
    <FormControl
      variant="outlined"
      fullWidth
      className={cn(classes.root, {
        [classes.selected]: isSelected,
        [classes.notSelected]: !isSelected,
      })}
    >
      {label && <InputLabel id="select-label">{label}</InputLabel>}
      <Select
        {...props}
        id="select"
        labelId="select-label"
        label={label}
        value={selectValue}
        dir={appStyles.rtl ? "rtl" : "ltr"}
        displayEmpty
        multiple={multiple}
        onChange={handleChangeDesktop}
        startAdornment={iconAdornment}
        className={cn(
          classes.input,
          { [classes.error]: isError },
          classes[groupPosition]
        )}
        aria-describedby="component-error-text"
      >
        {placeholder && (
          <MenuItem value="" disabled dir={appStyles.rtl ? "rtl" : "ltr"}>
            {placeholder}
          </MenuItem>
        )}
        {optionItems}
      </Select>
      {errorMessageComponent}
    </FormControl>
  );

  const handleChangeMobile = (event) => {
    let { value } = event.target;

    if (multiple) {
      const { options } = event.target;
      value = [];
      for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
    }

    setIsSelected(true);
    onChange(value);
  };

  const mobileContent = (
    <FormControl
      variant="outlined"
      fullWidth
      className={cn(classes.root, {
        [classes.selected]: isSelected,
        [classes.notSelected]: !isSelected,
      })}
    >
      {label && (
        <InputLabel shrink={multiple} htmlFor="select-label">
          {label}
        </InputLabel>
      )}
      <Select
        {...props}
        native
        label={label}
        multiple={multiple}
        value={selectValue}
        dir={appStyles.rtl ? "rtl" : "ltr"}
        onChange={handleChangeMobile}
        startAdornment={iconAdornment}
        className={cn(
          classes.input,
          { [classes.mobileMultipleSelect]: multiple },
          { [classes.error]: isError },
          classes[groupPosition]
        )}
        inputProps={{
          id: "select-label",
          size: 0,
        }}
        aria-describedby="component-error-text"
      >
        <option
          aria-label="None"
          disabled
          value=""
          dir={appStyles.rtl ? "rtl" : "ltr"}
        >
          {placeholder}
        </option>

        {optionItems}
      </Select>
      {errorMessageComponent}
    </FormControl>
  );

  return <Box sx={style}>{isMobile ? mobileContent : desktopContent}</Box>;
}

export default SelectInput;
