import _ from 'lodash';
import * as ACTION_TYPE from './constants';
import { loaderState } from './../reducerStateUpdaters';
import { reformatTimeFrames } from '../../utils/timeFrameReformat';

const { initLoader, setLoading, setDataLoaded, setError, resetErrorAndLoading } = loaderState;

const initialState = {
  loadingDeliveryOptions: false,
  deliveryOptions: [],
  lastDeliveryOptionsFetchTime: null,
  loadDeliveryOptionsError: null,
  loadingBranchesAvailability: false,
  branchesAvailability: {},
  lastBranchesAvailabiltyFetchTime: null,
  loadBranchesAvailabilityError: null,
  registrationGifts: initLoader(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'REDUX_STORAGE_LOAD':
      return {
        ...state,
        loadingDeliveryOptions: false,
        loadDeliveryOptionsError: null,
        loadingBranchesAvailability: false,
        loadBranchesAvailabilityError: null,
        registrationGifts: resetErrorAndLoading(state.registrationGifts),
      };
    case ACTION_TYPE.LOAD_DELIVERY_OPTIONS.STARTED:
      return {
        ...state,
        deliveryOptions: [],
        loadingDeliveryOptions: true,
        lastDeliveryOptionsFetchTime: new Date(),
        fetchDeliveryOptionsError: null,
      };
    case ACTION_TYPE.LOAD_DELIVERY_OPTIONS.SUCCESS:
      return {
        ...state,
        deliveryOptions: _.map(action.payload.deliveryOptions, reformatTimeFrames),
        loadingDeliveryOptions: false,
      };
    case ACTION_TYPE.LOAD_DELIVERY_OPTIONS.FAILED:
      return {
        ...state,
        deliveryOptions: [],
        loadingDeliveryOptions: false,
        error: action.payload.error,
      };
    case ACTION_TYPE.LOAD_DELIVERY_OPTIONS.SET:
      return {
        ...state,
        ...action.payload,
        loadingDeliveryOptions: false,
        loadBranchesAvailabilityError: null,
      };
    case ACTION_TYPE.LOAD_BRANCH_AVAILABILTY.STARTED:
      return {
        ...state,
        loadingBranchesAvailability: true,
        branchesAvailability: {},
        loadBranchesAvailabilityError: null,
      };
    case ACTION_TYPE.LOAD_BRANCH_AVAILABILTY.SUCCESS:
      return {
        ...state,
        loadingBranchesAvailability: false,
        branchesAvailability: _.keyBy(
          _.map(action.payload, branchAvailability => ({
            ...(branchAvailability.availability === 'TEMPORARILY_UNAVAILABLE' && { isUnavailable: true }),
            ..._.omit(branchAvailability, ['nextAvailableTimeFrame']),
          })),
          'branchId'
        ),
        lastBranchesAvailabiltyFetchTime: new Date(),
        loadBranchesAvailabilityError: null,
      };
    case ACTION_TYPE.LOAD_BRANCH_AVAILABILTY.FAILED:
      return {
        ...state,
        loadingBranchesAvailability: false,
        branchesAvailability: {},
        loadBranchesAvailabilityError: action.payload.error,
      };
    case ACTION_TYPE.LOAD_REGISTRATION_GIFTS_FROM_BRANCH.STARTED:
      return { ...state, registrationGifts: setLoading(state.registrationGifts) };
    case ACTION_TYPE.LOAD_REGISTRATION_GIFTS_FROM_BRANCH.SUCCESS:
      return { ...state, registrationGifts: setDataLoaded(state.registrationGifts, action.payload.registrationGifts) };
    case ACTION_TYPE.LOAD_REGISTRATION_GIFTS_FROM_BRANCH.FAILED:
      return { ...state, registrationGifts: setError(state.registrationGifts, action.payload.error) };
    default:
      return state;
  }
};
