import React, { useCallback } from "react";
import Helmet from "react-helmet";
import { withPrefix } from "gatsby";
import {
  get,
  compact,
  startsWith,
  map,
  includes,
  isEmpty,
  find,
  filter,
  uniqBy,
} from "lodash";
import {
  getAwHeadScript,
  getGtmHeadScript,
  initGtagScript,
  gtagDataLayerInitScript,
} from "../utils/gtm";
import { getAppMediaSrc, mediaTypes } from "../utils/media";

export default ({ app, T, location, appStyles, appStoreId, trackings }) => {
  const isFullscreen =
    !(
      app.mobileAuthViewOpen ||
      app.authModalOpen ||
      appStyles.hasScrollableHomepageLayout
    ) && location.pathname === "/";

  const height = isFullscreen ? "100%" : "auto";
  const overflow = isFullscreen ? "hidden" : "auto";
  const position = isFullscreen ? "fixed" : "relative";
  const letterSpacing = appStyles.letterSpacing;
  const background = appStyles.backgroundImage
    ? `url(${withPrefix(appStyles.backgroundImage)})`
    : appStyles.background;
  const fontSize = appStyles.baseFontSize || 16;

  const HTMLStyleAttribute = app.isSSR
    ? { height, background, fontSize, overflow, position, letterSpacing }
    : compact([
        `height: ${height};`,
        `font-size: ${fontSize}px;`,
        `overflow: ${overflow};`,
        `position: ${position};`,
        letterSpacing && `letter-spacing: ${letterSpacing};`,
        background && `background: ${background};`,
      ]).join("");

  const googleFontLink = toGoogleFontLink(appStyles.googleFonts);
  const facebookMetaTagId = getFacebookMetaTagId(trackings);
  const trackingIds = map(
    uniqBy(filter(trackings, { type: "gtm" }), "trackingId"),
    "trackingId",
  );
  const awIds = filter(trackingIds, (id) => startsWith(id, "AW"));
  const gtmIds = filter(trackingIds, (id) => startsWith(id, "GTM"));

  return (
    <Helmet
      defer={false}
      title={`${T("{{business_name}} - Just Order")}`}
      meta={compact([
        {
          name: "description",
          content: `${T("{{business_name}} - Just Order")}`,
        },
        {
          name: "keywords",
          content: `${T("{{business_name}}")}, ${T("food")}`,
        },
        {
          name: "viewport",
          content:
            "initial-scale=1.0, viewport-fit=cover, user-scalable=0, maximum-scale=1.0, width=device-width",
        },
        ...(appStoreId
          ? [{ name: "apple-itunes-app", content: `app-id=${appStoreId}` }]
          : []),
        ...(facebookMetaTagId ? {
          name: "facebook-domain-verification",
          content: facebookMetaTagId,
        } : []),
      ])}
      link={compact([
        {
          rel: "icon",
          href: getAppMediaSrc(mediaTypes.appIcon, appStyles),
        },
        googleFontLink && {
          rel: "stylesheet",
          href: googleFontLink,
        },
      ])}
    >
      {map(awIds, (awId) => (
        <script async src={getAwHeadScript(awId)} />
      ))}
      <script>{gtagDataLayerInitScript}</script>
      <script>dataLayer=window.dataLayer || [];</script>
      {map(gtmIds, (gtmId) => (
        <script>{getGtmHeadScript(gtmId)}</script>
      ))}
      {!isEmpty(awIds) && <script>{initGtagScript(awIds)}</script>}

      <html style={HTMLStyleAttribute} />
    </Helmet>
  );
};

const googleFontBaseUrl = "https://fonts.googleapis.com/css2";

const toGoogleFontLink = (googleFonts) => {
  if (isEmpty(googleFonts)) {
    return null;
  }
  const googleFontQueryString = map(googleFonts, (googleFont) => {
    const [family, weights = ""] = googleFont.split(":");
    const hasItalics = includes(weights, "i");
    const hasWeights = Boolean(weights.length);
    const fontQS = `family=${family}${hasItalics || hasWeights ? ":" : ""}${
      hasItalics ? "ital" : ""
    }${hasItalics && hasWeights ? "," : ""}${hasWeights ? "wght@" : ""}${map(
      weights.split(","),
      (weight) => {
        return `${
          hasItalics ? (includes(weight, "i") ? "1," : "0,") : ""
        }${parseInt(weight, 10)}`;
      },
    ).join(";")}`;
    return fontQS;
  }).join("&");
  return `${googleFontBaseUrl}?${googleFontQueryString}`;
};

const getFacebookMetaTagId = (trackings) => {
  const fbTracking = find(trackings, { type: "facebook" });
  if (fbTracking) {
    const metaTagId = get(
      find(fbTracking.events, {
        type: "MetaTagVerification",
      }),
      "params.metaTag",
    );
    return metaTagId;
  }
};
