import React from "react";
import styles from "./happyspizza.module.scss";
import classnames from "classnames";
import compact from "lodash/compact";
import ExternalLink from "../ExternalLink";
import HeaderUserProfileLink from "./HeaderUserProfileLink";
import { Link } from "../../utils/location";

const externalLinkStyle = {
  fontFamily: "program-narrow",
  fontWeight: 900,
  letterSpacing: 2,
  fontSize: 24,
  color: "white",
  textTransform: "uppercase",
  padding: "2px 12px",
};

const externalSmallLinkStyle = {
  fontFamily: "proxima-nova-extra-condensed",
  fontWeight: 700,
  letterSpacing: 2,
  fontSize: 16,
  color: "#e8232e",
  textTransform: "uppercase",
  padding: "0 12px",
};

export default ({
  appStyles,
  T,
  showLabel,
  loggedIn,
  userDetails,
  loginLabelText,
  loginLabelDesktopExtraText,
  onLoginClicked,
  onUserOpenAccountMenuClicked,
  amountOfGiftsUnseen,
  checkoutOpen,
}) => (
  <div className={styles.HeaderColumn}>
    <div
      className={classnames(styles.HeaderRow, styles.HideOnMobile)}
      style={{ marginBottom: 4 }}
    >
      <ExternalLink
        notBlank
        link="https://www.happyspizza.com/"
        appStyles={appStyles}
        style={{
          borderRight: `2px solid #FCEE23`,
          ...externalSmallLinkStyle,
        }}
      >
        Home
      </ExternalLink>
      <ExternalLink
        link="https://www.happyspizza.com/catering/"
        appStyles={appStyles}
        style={{
          borderRight: `2px solid #FCEE23`,
          ...externalSmallLinkStyle,
        }}
      >
        Catering
      </ExternalLink>
      <ExternalLink
        link="https://www.happyspizza.com/franchising/"
        appStyles={appStyles}
        style={{
          borderRight: `2px solid #FCEE23`,
          ...externalSmallLinkStyle,
        }}
      >
        Franchising
      </ExternalLink>
      <ExternalLink
        link="https://www.happyspizza.com/careers/"
        appStyles={appStyles}
        style={externalSmallLinkStyle}
      >
        Careers
      </ExternalLink>
    </div>
    <div className={styles.HeaderRow}>
      {!checkoutOpen &&
        compact([
          <ExternalLink
            notBlank
            link="https://www.happyspizza.com/locations/"
            style={externalLinkStyle}
            appStyles={appStyles}
            className={classnames(styles.HideOnMobile, styles.HideOnTablet)}
          >
            Store Locator
          </ExternalLink>,

          loggedIn && (
            <Link
              to="/gifts"
              style={externalLinkStyle}
              appStyles={appStyles}
              className={classnames(styles.HideOnMobile, styles.HideOnTablet)}
            >
              Rewards
            </Link>
          ),
        ])}
      <HeaderUserProfileLink
        T={T}
        appStyles={appStyles}
        showLabel={showLabel}
        loggedIn={loggedIn}
        userDetails={userDetails}
        loginLabelText={loginLabelText}
        loginLabelDesktopExtraText={loginLabelDesktopExtraText}
        onLoginClicked={onLoginClicked}
        onUserOpenAccountMenuClicked={onUserOpenAccountMenuClicked}
        amountOfGiftsUnseen={amountOfGiftsUnseen}
      />
    </div>
  </div>
);
