import { jssPreset } from '@material-ui/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';

const stylesProviderProps = {
  jss: create({
    plugins: [...jssPreset().plugins, rtl()],
    insertionPoint: `mui-inject-first`,
  }),
};

export default stylesProviderProps;
