export const SET_KEYBOARD_STATUS = "SET_KEYBOARD_STATUS";
export const KEYBOARD_OPENED = "KEYBOARD_OPENED";
export const KEYBOARD_CLOSED = "KEYBOARD_CLOSED";
export const TOGGLE_CHECKOUT_VIEW = "TOGGLE_CHECKOUT_VIEW";
export const OPEN_CHECKOUT_VIEW = "OPEN_CHECKOUT_VIEW";
export const CLOSE_CHECKOUT_VIEW = "CLOSE_CHECKOUT_VIEW";
export const OPEN_AUTH_MODAL = "OPEN_AUTH_MODAL";
export const CLOSE_AUTH_MODAL = "CLOSE_AUTH_MODAL";
export const OPEN_MOBILE_AUTH_VIEW = "OPEN_MOBILE_AUTH_VIEW";
export const CLOSE_MOBILE_AUTH_VIEW = "CLOSE_MOBILE_AUTH_VIEW";
export const RECEIVE_CAMERA_PERMISSIONS_ANDROID =
  "RECEIVE_CAMERA_PERMISSIONS_ANDROID";
export const SET_CAMERA_PERMISSIONS_ANDROID = "SET_CAMERA_PERMISSIONS_ANDROID";
export const SET_KIOSK_LOCATION_ID = "SET_KIOSK_LOCATION_ID";
export const SET_GTM_IDS = "SET_GTM_IDS";
export const SET_IN_STORE_SIGNUP_BRANCHID = "SET_IN_STORE_SIGNUP_BRANCHID";
export const GOOGLE_LOADED = "GOOGLE_LOADED";
export const SET_GROUP_APP_MODE_PARAMS = "SET_GROUP_APP_MODE_PARAMS";
export const SET_EDITOR_APP_STYLES = "SET_EDITOR_APP_STYLES";
export const RESET_EDITOR_APP_STYLES = "RESET_EDITOR_APP_STYLES";
export const SET_EDITOR_PROMOTIONS = "SET_EDITOR_PROMOTIONS";
export const RESET_EDITOR_PROMOTIONS = "RESET_EDITOR_PROMOTIONS";
