import React from 'react';
import _ from 'lodash';
import Steps, { Step } from 'rc-steps';
import styles from './index.module.scss';

const giftStepTypeToHumanReadableText = {
  SELECT_ITEM_TO_BUY: 'Choose an Item',
  CUSTOMIZE_ITEM_TO_BUY: 'Customize Item',
  SELECT_ITEM_YOU_GET_WITH_DISCOUNT: 'Select your reward',
  CUSTOMIZE_ITEM_YOU_GET_WITH_DISCOUNT: 'Customize your reward',
  SELECT_DEAL_OPTION: 'Select an Offer',
  MONEY_GIFT: 'Redeem and Start Your Order',
  GLOBAL_DISCOUNT_GIFT: 'Redeem and Start Your Order',
};

export default class GiftsFlowHeader extends React.Component {
  state = { deltaForStepToBeInView: 0 };
  componentDidMount() {
    this.currentStepEl = document.querySelector('.rc-steps-item-process');
    this.stepsContainer = document.querySelector('.rc-steps');
    this.handleResize = _.throttle(this.updateStepPosition, 300);
    window.addEventListener('resize', this.handleResize);
  }

  updateStepPosition = () => {
    const PADDING = 16;
    const { appStyles = {} } = this.props;
    if (this.currentStepEl) {
      const stepOffsetLeft = this.currentStepEl.offsetLeft;
      const stepWidth = this.currentStepEl.offsetWidth;
      const windowWidth = window.innerWidth;
      const stepOffsetRight = windowWidth - (stepOffsetLeft + stepWidth);

      const deltaForStepToBeInView = appStyles.rtl
        ? windowWidth - (stepOffsetRight + stepWidth)
        : windowWidth - (stepOffsetLeft + stepWidth);

      const effectiveDelta =
        deltaForStepToBeInView < 0
          ? appStyles.rtl
            ? PADDING - deltaForStepToBeInView
            : deltaForStepToBeInView - PADDING
          : 0;

      this.stepsContainer.style.transition = `all 0.3s ease-in-out`;
      this.stepsContainer.style.transform = `translateX(${effectiveDelta}px)`;
    }
  };

  render() {
    const { giftRedeem, T = _.identity, appStyles = {} } = this.props;

    if (giftRedeem.currentStepIndex < 0) {
      return (
        <div className={styles.HeaderNoSteps} style={{ color: appStyles.accentColor }}>
          <h2 style={appStyles.GiftsHeaderTitle}>{T(giftRedeem.headerTitle)}</h2>
        </div>
      );
    }

    if (!_.isEmpty(giftRedeem.steps)) {
      if (giftRedeem.steps.length > 1) {
        return (
          <Steps
            current={giftRedeem.currentStepIndex}
            style={_.omit(appStyles.GiftsHeaderSteps, 'fontSize')}
            labelPlacement="vertical"
            ref={el => {
              this.currentStepEl = document.querySelector('.rc-steps-item-process');
              this.stepsContainer = document.querySelector('.rc-steps');
              this.updateStepPosition();
            }}
          >
            {_.map(giftRedeem.steps, (step, index) => (
              <Step
                id={`step-${index}`}
                key={index}
                title={T(giftStepTypeToHumanReadableText[step.type])}
                style={{ color: appStyles.accentColor }}
                onClick={e => console.log('Not implemented - go to step: ', index)}
                className={appStyles.rtl && styles.RTL}
              />
            ))}
          </Steps>
        );
      }
      if (giftRedeem.steps.length === 1) {
        return (
          <div className={styles.HeaderNoSteps} style={{ color: appStyles.accentColor }}>
            <h2 style={appStyles.GiftsHeaderTitle}>
              {T(giftStepTypeToHumanReadableText[giftRedeem.steps[giftRedeem.currentStepIndex].type])}
            </h2>
          </div>
        );
      }
    }
  }
}
