import React from "react";
import Modal from "react-responsive-modal";
import Image from "../image";
import Button from "../Button";
import styles from "./index.module.scss";
import { getAppMedia, mediaTypes } from "../../utils/media";

export default ({ T, appStyles, onClose, open, backgroundColor }) => {
  const temporarySplashScreen =
    getAppMedia(mediaTypes.temporarySplashScreen, appStyles) || {};

  return temporarySplashScreen.imageKey ? (
    <Modal
      open={open}
      onClose={onClose}
      styles={{
        modal: { backgroundColor },
        closeButton: { color: appStyles.splashScreenButtonColor },
      }}
      classNames={{
        overlay: styles.Overlay,
        modal: styles.Modal,
        closeButton: styles.CloseButton,
        transitionEnter: styles.TransitionEnter,
        transitionEnterActive: styles.TransitionEnterActive,
        transitionExit: styles.TransitionExit,
        transitionExitActive: styles.TransitionExitActive,
      }}
      focusTrapped
      animationDuration={1000}
    >
      <div className={styles.ImageWrapper}>
        <Image
          mediaType={mediaTypes.temporarySplashScreen}
          imagePreview={temporarySplashScreen.imagePreview}
          mediaKey={temporarySplashScreen.imageKey}
          sizes="768px"
          imgStyle={{ objectFit: "cover" }}
          wrapperStyle={{ width: "100%", height: "100%" }}
        />
        <Button
          centered
          linkStyle
          appStyles={appStyles}
          onClick={onClose}
          classNames={styles.SkipButton}
          style={{ color: appStyles.splashScreenButtonColor }}
          label={T("Skip")}
        >
          {T("Skip")}
        </Button>
      </div>
    </Modal>
  ) : null;
};
