import React from "react";
import styles from "./index.module.scss";
import classnames from "classnames";
import scroll from "scroll";

export default ({ children, color, narrow, ...headerStyles }) => (
  <div
    className={classnames(
      styles.HeaderTitleWrapper,
      "noselect",
      narrow && styles.Narrow,
    )}
    style={{ color: color }}
    onClick={() => {
      console.log("scroll");
      scroll.top(0);
    }}
  >
    <span />
    <span className={styles.HeaderTitle} style={headerStyles} role="heading">
      {children}
    </span>
  </div>
);
