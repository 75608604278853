import React, { useState, useCallback } from "react";
import { map, get, isEmpty } from "lodash";
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Fab,
  ButtonGroup,
  Button,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";

import IamHereIcon from "@material-ui/icons/WhereToVote";
import { makeStyles } from "@material-ui/core/styles";
import locateMe from "../../utils/locateMe";
import WazeButton from "../WazeButton";

export default function WaitingOrder({
  order,
  location,
  T,
  onNotifyImHere,
  onClose,
}) {
  const classes = useStyles();
  const offerToSendLocation = get(order, "waitingFor.shouldSendGeocode");

  const [showCanWeSendYourLocation, setShowCanWeSendYourLocation] = useState(
    false,
  );
  const [locatingUser, setLocatingUser] = useState(false);
  const [locatingUserError, setLocatingUserError] = useState(false);
  const handleImHereClicked = useCallback(() =>
    setShowCanWeSendYourLocation(true),
  );

  const handleSendLocationAndNotifyImHere = useCallback(() => {
    setLocatingUserError(false);
    setLocatingUser(true);
    locateMe()
      .then((coords) => {
        setLocatingUser(false);
        handleJustNotifyImHere(coords);
      })
      .catch((error) => {
        console.log(error);
        setLocatingUser(false);
        setLocatingUserError(T("Could not get your Location"));
      });
  });

  const handleJustNotifyImHere = useCallback((coords) => {
    onNotifyImHere(order.id, coords);
    onClose();
  });
  return (
    <Grid container spacing={4} direction="column" alignItems="center">
      <Backdrop className={classes.backdrop} open={locatingUser}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item>
        <Typography variant="h2" align="center">
          {T(get(order, "chosenServingOption.servingOptionTag"))} {T("from")}{" "}
          {location.name}
        </Typography>

        <Typography variant="body2" align="center">
          {location.address}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" align="center">
          {T("Order")} #{order.posTransactionId}
          {!isEmpty(order.pickupTime) && ` | ${order.pickupTime}`}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h2" align="center">
          {T("Order Items")}
        </Typography>
        <OrderItems order={order} />
      </Grid>

      <Grid container item justify="center" spacing={1}>
        {get(order, "waitingFor.imHere") && (
          <>
            <Grid item>
              <Typography variant="caption" align="center">
                {T(
                  "Click below to notify the location that you are here to pick up your order",
                )}
              </Typography>
            </Grid>
            {offerToSendLocation && showCanWeSendYourLocation ? (
              <>
                <Grid item>
                  <Typography variant="body2" color="primary">
                    <strong>{T("Share your current location with us?")}</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <ButtonGroup
                    variant="contained"
                    aria-label={T("Share your current location with us?")}
                  >
                    <Button
                      color="primary"
                      onClick={handleSendLocationAndNotifyImHere}
                    >
                      {T("Sure")}
                    </Button>
                    <Button onClick={handleJustNotifyImHere}>
                      {T("No just say I'm Here")}
                    </Button>
                  </ButtonGroup>
                </Grid>
              </>
            ) : (
              <Grid item>
                <Fab
                  aria-label="I am Here"
                  onClick={
                    offerToSendLocation
                      ? handleImHereClicked
                      : handleJustNotifyImHere
                  }
                  variant="extended"
                  color="primary"
                >
                  {T("Send I am here now")}
                  <IamHereIcon />
                </Fab>
              </Grid>
            )}
            {locatingUserError && (
              <Grid item>
                <Typography variant="caption" align="center" color="error">
                  {locatingUserError}
                </Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Grid container item justify="center">
        <Grid item>
          <WazeButton
            linkTitle={location.address}
            geoPoint={location.geoPoint}
            time={new Date().getTime()}
            T={T}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

const OrderItems = ({ order }) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="Order Items">
        <TableBody>
          {map(order.orderItems, (item, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <i>#{index + 1}</i>
              </TableCell>
              <TableCell component="th" scope="row">
                {item.configuredMenuItemOrderData.itemName}
              </TableCell>
              <TableCell align="right">
                {item.count > 1 && `X ${item.count}`}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  table: {
    // minWidth: 650,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    // color: "#fff",
  },
}));
