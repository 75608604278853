import React from "react";
import noop from "lodash/noop";
import isEmpty from "lodash/isEmpty";
import { GoogleApiWrapper } from "google-maps-react";
import AppContainer from "../AppContainer";
import Loader from "../Loader";

class GoogleMapsLoader extends React.Component {
  componentDidMount() {
    const { loaded, google, onLoaded = noop } = this.props;
    if (loaded) {
      onLoaded(google);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { loaded, google, onLoaded = noop } = nextProps;
    if (nextProps.loaded) {
      onLoaded(google);
    }
  }

  render() {
    return null;
  }
}

const toGoogleLanguageCode = (languageCode) => {
  switch (languageCode) {
    case "he":
      return "iw";
    case "en_us":
    case "en":
    case "en_US":
      return "en";
    case "de":
      return "de";
    default:
      return "en";
  }
};

const GOOGLE_MAPS_API_KEY = "AIzaSyDMFj-OseCoZgiq2mYCFYefmW5Uo-gCJ9A"; // TODO: to env. var.

const LoadingContainer = () => null;

export default GoogleApiWrapper((props) => ({
  libraries: props.loadPlaces ? ["places"] : [],
  language: toGoogleLanguageCode(props.language),
  LoadingContainer,
  apiKey: isEmpty(props.apiKey) ? GOOGLE_MAPS_API_KEY : props.apiKey,
  // channel: props.channel,
}))(GoogleMapsLoader);
