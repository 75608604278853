import React from "react";
import noop from "lodash/noop";
import styles from "./index.module.scss";
import classnames from "classnames";
import { navigateTo } from "../../utils/location";
import openNativeLink from "../../utils/openNativeLink";

export default ({
  children,
  style,
  linkStyle,
  appStyles = {},
  slim,
  marginTop,
  loading,
  completed,
  centered,
  onClick = noop,
  to,
  spread,
  classNames,
  round,
  isExternalLink,
  label = "",
}) => {
  const { Button = {}, LinkButton = {}, SlimButton = {} } = appStyles;
  const nativeMode = typeof window !== "undefined" ? window.isNativeApp : false;

  return (
    <a
      style={{
        color: "#fff",
        ...Button,
        ...style,
        ...(slim && SlimButton),
        ...(linkStyle && LinkButton),
        ...(linkStyle && {
          color: appStyles.actionColor,
          backgroundColor: "transparent",
          boxShadow: "none",
          border: "none",
          backgroundImage: "none",
        }),
      }}
      target={isExternalLink && !nativeMode && "_blank"}
      href={to ? (nativeMode ? "#" : to) : "#"}
      onClick={(e) => {
        const hrefTarget = to ? (nativeMode ? null : to) : null;
        if (hrefTarget === null && !isExternalLink) {
          e.preventDefault();
        }
        if (!(to && isExternalLink && !nativeMode)) {
          e.preventDefault();
        }
        if (!loading && !completed) {
          onClick(e);
          if (to) {
            if (isExternalLink && nativeMode) {
              console.log("open native link:", to);
              openNativeLink(to)(e);
            } else if (!(to && isExternalLink && !nativeMode)) {
              console.log("navigateTo", to);
              navigateTo(to);
            }
          }
        }
      }}
      className={classnames(
        styles.Button,
        linkStyle && styles.LinkStyle,
        "noselect",
        (centered || (appStyles.noIcons && !spread)) && styles.Centered,
        slim && styles.Slim,
        marginTop && styles.MarginTop,
        spread && styles.Spread,
        round && styles.Round,
        appStyles.rtl && styles.RTL,
        classNames,
      )}
      aria-labelledby={label}
      role="button"
    >
      {!loading && !completed ? children : null}
      {(loading || completed) && (
        <span
          className={classnames(
            styles.CircleLoader,
            completed && styles.LoadComplete,
          )}
        >
          {completed && (
            <span className={classnames(styles.Checkmark, styles.Draw)} />
          )}
        </span>
      )}
    </a>
  );
};
