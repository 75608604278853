import React from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';

export default ({ small, appStyles = {}, classNames, style }) => {
  const { Loader } = appStyles;
  return (
    <span
      className={classnames(styles.CircleLoader, small && styles.Small, classNames)}
      style={{ ...Loader, ...style }}
    />
  );
};
