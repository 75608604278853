import React, { useCallback, useEffect } from "react";
import { identity, get, map, find, isEmpty } from "lodash";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Backdrop, Divider, Grid, Paper } from "@material-ui/core";
import Draggable from "react-draggable";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import CloseIcon from "@material-ui/icons/Close";
import IamHereIcon from "@material-ui/icons/WhereToVote";
import ArciveIcon from "@material-ui/icons/Archive";
import DoneIcon from "@material-ui/icons/AssignmentTurnedIn";
import useInterval from "@use-it/interval";
import WaitingOrder from "./waiting-order";
import { format } from "date-fns";

export default function WaitingOrders({
  T = identity,
  user,
  loadWaitingOrders,
  lastOrder,
  onNotifyImHere,
  onArchive,
  branches,
  pollingInterval = 60 * 1000,
  shrink,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [openOrderId, setOpenOrderId] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);
  const loading = get(user, "waitingOrders.loading");

  useEffect(() => {
    const lastOrderId = get(lastOrder, "checkoutResponse.order.id");

    user.loggedIn
      ? loadWaitingOrders()
      : lastOrderId && loadWaitingOrders(lastOrderId);
  }, [user.loggedIn, lastOrder]);

  useInterval(() => {
    const lastOrderId = get(lastOrder, "checkoutResponse.order.id");
    user.loggedIn
      ? loadWaitingOrders()
      : lastOrderId && loadWaitingOrders(lastOrderId);
  }, pollingInterval);

  const toggleOrder = (orderId) => () => {
    setOpenOrderId(openOrderId !== orderId ? orderId : false);
  };

  const handleArchiveOrder = useCallback(() => {
    if (openOrderId) {
      onArchive(openOrderId);
      setOpenOrderId(false);
    }
  }, [openOrderId]);

  const handleNotifyImHere = useCallback((orderId, coords) => {
    console.log({ orderId, coords });
    onNotifyImHere(orderId, coords);
  });

  const selectedOrder = find(user.waitingOrders.data, { id: openOrderId });
  const selectedOrderLocation =
    selectedOrder && find(branches, { id: selectedOrder.branchId });

  return (
    <div className={classes.root}>
      <Backdrop open={openOrderId}>
        <Paper className={classes.orderContainer}>
          {selectedOrder && (
            <WaitingOrder
              order={selectedOrder}
              T={T}
              location={selectedOrderLocation}
              onNotifyImHere={handleNotifyImHere}
              onClose={() => setOpenOrderId(false)}
            />
          )}
        </Paper>
      </Backdrop>

      {map(user.waitingOrders.data, (order, index) => (
        <Draggable
          key={order.id}
          position={
            openOrderId && openOrderId === order.id ? { x: 0, y: 0 } : undefined
          }
        >
          <SpeedDial
            ariaLabel="Waiting Order"
            className={classes.speedDial}
            style={
              openOrderId && openOrderId === order.id
                ? {
                    bottom: theme.spacing(2),
                    top: "unset",
                  }
                : { top: theme.spacing(2 + 8 * index) }
            }
            hidden={openOrderId && openOrderId !== order.id}
            icon={
              <>
                <SpeedDialIcon
                  icon={
                    openOrderId ? (
                      <CloseIcon />
                    ) : order.waitingFor.imHere ? (
                      <IamHereIcon />
                    ) : (
                      <DoneIcon />
                    )
                  }
                />
                {!openOrderId && !shrink && (
                  <Grid
                    container
                    alignItems="center"
                    wrap="nowrap"
                    direction="column"
                  >
                    <Grid item>
                      {order.waitingFor.imHere
                        ? T("I'm Here!")
                        : T("Completed")}
                    </Grid>

                    <Grid item container spacing={1}>
                      <Grid item>
                        <small>
                          <code>
                            {T(
                              `${T(
                                get(
                                  order,
                                  "chosenServingOption.servingOptionTag",
                                ),
                              )}`,
                            )}
                          </code>
                        </small>
                      </Grid>
                      {!isEmpty(order.pickupTime) &&
                        order.pickupTime !== "Now" && (
                          <Grid item>
                            <small>
                              <code>{format(order.pickupTime, "h:mm a")}</code>
                            </small>
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                )}
              </>
            }
            FabProps={{
              disabled: loading,
              variant: openOrderId || hidden || shrink ? "round" : "extended",
            }}
            onClick={toggleOrder(order.id)}
            open={openOrderId === order.id}
          >
            <SpeedDialAction
              key="archive"
              className={classes.speedDialFab}
              FabProps={{
                disabled: loading,
                size: "large",
                variant: "extended",
              }}
              icon={
                <>
                  <span className={classes.speedDialFabLabel}>Archive</span>
                  <ArciveIcon />
                </>
              }
              onClick={handleArchiveOrder}
            />
          </SpeedDial>
        </Draggable>
      ))}
    </div>
  );
}

const useStyles = makeStyles((theme) => {
  const orderContainerPadding = theme.spacing(4);
  const computedWidthHeight = `calc(100% - 2 * ${orderContainerPadding}px)`;
  return {
    root: {
      position: "fixed",
      zIndex: theme.zIndex.drawer + 1,
      transform: "translateZ(0px)",
      height: "100%",
      width: "100%",
      top: 0,
      right: 0,
      flexGrow: 1,
      pointerEvents: "none",
    },
    speedDial: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(1),
      alignItems: "flex-end",
    },

    speedDialFab: {
      margin: 0,
    },
    speedDialFabLabel: {
      marginRight: theme.spacing(0.5),
    },
    orderContainer: {
      padding: orderContainerPadding,
      borderRadius: 0,
      width: computedWidthHeight,
      height: computedWidthHeight,
      overflowY: "scroll",
      pointerEvents: "all",
    },
  };
});
