import { Box, Typography } from "@material-ui/core";
import { PersonPinCircleOutlined } from "@material-ui/icons";
import React, { useMemo } from "react";
import SelectInput from "../Inputs/Select";

function PreferredLocations({
  availableLocations,
  errorMessage,
  required,
  T,
  label,
  style,
  ...props
}) {
  const locationOptions = useMemo(
    () =>
      availableLocations.map(({ name, id }) => ({ label: name, value: id })),
    [availableLocations]
  );

  return (
    <Box sx={style}>
      {label || (<div style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
        <Typography variant="subtitle1" >
          {T(`Preferred locations ${!require ? "(optional)" : ""}`)}
        </Typography>
      </div>)}
      <SelectInput
        {...props}
        options={locationOptions}
        customIconAdornment={(<PersonPinCircleOutlined style={{ paddingRight: 8, color: "#757575" }}/>)}
        placeholder={T("Please select preferred locations")}
        multiple
        isError={Boolean(errorMessage)}
        errorMessage={errorMessage}
      />
    </Box>
  );
}

export default PreferredLocations;
