import React from "react";
import { omit, values, get, map, merge } from "lodash";
import { Location } from "@reach/router";
import Layout from "./src/layouts/index";

export default ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  const {
    location: { pathname },
  } = props;

  if (
    props.custom404 ||
    element.key === "/404.html" ||
    pathname === "/404.html" ||
    pathname === "/404/"
  ) {
    return element;
  }
  // const layoutData = merge(
  //   ...map(values(get(props, "pageResources.staticQueryResults")), "data"),
  // );
  // console.log(element);
  return (
    <Location>
      {({ location }) => (
        <Layout {...omit(props, "data")} location={location}>
          {element}
        </Layout>
      )}
    </Location>
  );
};
