import castArray from "lodash/castArray";
import templateSettings from "lodash/templateSettings";
import template from "lodash/template";
import compact from "lodash/compact";
import get from "lodash/get";
import { withHandlers, compose } from "recompose";
import { withNamespaces } from "react-i18next";

templateSettings.interpolate = /%%([\s\S]+?)%%/g;

const interpolate = (message, dictionary) => {
  return template(message)(dictionary);
};

export default compose(
  withNamespaces((props) =>
    compact([
      ...castArray(props.namespaces),
      ...castArray(get(props.pageContext, "namespaces")),
    ]),
  ),
  withHandlers({
    T: (props) => (toTranslate, varsDictionay) =>
      props.tReady
        ? interpolate(
            props.t(
              toTranslate,
              props.pageContext.interpolationTemplates || [],
            ),
            varsDictionay,
          )
        : interpolate(toTranslate, varsDictionay),
  }),
);
