import { combineReducers } from 'redux';
import * as storage from 'redux-storage';

import menuItemsConfiguration from './menuItemsConfiguration/reducer';
import order from './order/reducer';
import user from './user/reducer';
import header from './header/reducer';
import geoLocation from './geoLocation/reducer';
import locations from './locations/reducer';
import giftRedeem from './giftRedeem/reducer';
import nativeTabs from './nativeTabs/reducer';
import app from './app/reducer';

require('isomorphic-fetch');

export default function createReducer(injectedReducers) {
  return storage.reducer(
    combineReducers({
      user,
      menuItemsConfiguration,
      order,
      header,
      geoLocation,
      locations,
      giftRedeem,
      nativeTabs,
      app,
      ...injectedReducers,
    })
  );
}
