import { get } from "lodash";

export const updaterState = {
  initUpdaterState: (entityState) => ({
    ...entityState,
    sending: false,
    sent: false,
    error: null,
  }),

  setUpdaterSending: (entityState) => ({
    ...entityState,
    sending: true,
    sent: false,
    error: null,
  }),

  setUpdaterSent: (entityState, data) => ({
    ...entityState,
    sending: false,
    sent: true,
    data,
    error: null,
  }),

  setUpdaterError: (entityState, error) => ({
    ...entityState,
    sending: false,
    sent: false,
    error: error || "failed",
  }),
};

export const loaderState = {
  initLoader: (entityState) => ({
    ...entityState,
    loading: false,
    loadTimestamp: null,
    data: null,
    error: null,
  }),

  setLoading: (entityState) => ({
    ...entityState,
    loading: true,
    loadTimestamp: new Date(),
    error: null,
  }),

  setDataLoaded: (entityState, data) => ({
    ...entityState,
    loading: false,
    loadTimestamp: get(entityState, "loadTimestamp") || new Date(),
    data,
    error: null,
  }),

  setError: (entityState, error) => ({
    ...entityState,
    loading: false,
    loadTimestamp: null,
    error,
  }),

  resetErrorAndLoading: (entityState) => ({
    ...entityState,
    loading: false,
    error: null,
  }),
};
