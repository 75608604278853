import React, { useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import HomeIcon from "../icons/Home.svg";
import { get, findIndex, map, set, some, startsWith } from "lodash";
import IceCreamIcon from "../icons/ice-cream.svg";
import NewOrderIcon from "../icons/NewOrder.svg";
import NewOrderPizzaIcon from "../icons/NewOrderPizza.svg";
import ReorderIcon from "../icons/Reorder.svg";
import PizzaReorderIcon from "../icons/PizzaReorder.svg";
import PizzaCarryoutIcon from "../icons/PizzaCarryout.svg";
import GiftIcon from "../icons/Gift.svg";
import ScanIcon from "../icons/Scan.svg";
import { useLocation } from "@reach/router";
import Badge from "@material-ui/core/Badge";
import { getParams, navigateTo, setParams } from "../../utils/location";
import { LOGIN_TYPES } from "../LoginView";

const TabIconWrapper = ({ children }) => {
  const classes = useStyles();

  return <span className={classes.icon}>{children}</span>;
};
export default function AppNavigationTabs({
  T,
  appStyles,
  context,
  onOpenMobileAuthView: openMobileAuthView,
  onCloseMobileAuthView: closeMobileAuthView,
}) {
  const classes = useStyles();
  const tabs = appStyles.isPizza
    ? pizzaTabs
    : appStyles.isIceCream
    ? iceCreamTabs
    : defaultTabs;

  const [value, setValue] = React.useState(2);

  const navigateToTargetOrLoginMobile = useCallback(
    (target, type) => {
      console.log({ type, target, openMobileAuthView });
      context.user.loggedIn ? navigateTo(target) : openMobileAuthView(type);
    },
    [openMobileAuthView, context.user.loggedIn],
  );

  // const handleChange = (event, newValue) => {};

  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
    const tabNextIndex = findIndex(tabs, ({ pathMatch, exact }) =>
      exact
        ? location.pathname === pathMatch
        : some(pathMatch, (path) => startsWith(location.pathname, path)),
    );
    console.log(tabNextIndex);
    setValue(tabNextIndex);
  }, [location]);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Tabs
          value={value}
          // onChange={handleChange}
          variant="fullWidth"
          scrollButtons="off"
          aria-label="app-navigator"
          textColor="primary"
        >
          {map(tabs, (tab, index) => (
            <Tab
              wrapped
              icon={tab.icon(context)}
              label={T(tab.label)}
              aria-label={T(tab.label)}
              onClick={() =>
                tab.onClick &&
                tab.onClick({
                  ...context,
                  navigateToTargetOrLoginMobile,
                  closeMobileAuthView,
                })
              }
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 72,
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    background: "white", // modify this for customization of app navigation
    top: "auto",
    bottom: 0,
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 24,
    width: 26,
    fill: "currentColor",
    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
}));

const defaultTabs = [
  {
    icon: () => (
      <TabIconWrapper>
        <HomeIcon />
      </TabIconWrapper>
    ),
    label: "Home",
    pathMatch: "/",
    exact: true,
    onClick: (context) => {
      context.closeMobileAuthView();
      navigateTo("/");
    },
  },
  {
    icon: (context) => (
      <Badge
        badgeContent={get(context, "user.amountOfGiftsUnseen", 0)}
        color="secondary"
      >
        <TabIconWrapper>
          <GiftIcon />
        </TabIconWrapper>
      </Badge>
    ),
    onClick: (context) => {
      context.navigateToTargetOrLoginMobile("/gifts", LOGIN_TYPES.GIFTS);
    },
    label: "Rewards",
    pathMatch: ["/gift"],
  },
  {
    label: "Order",
    icon: (context) => {
      const branchId = get(context, "order.branchId");
      const servingOptionType = get(context, "order.servingOptionType", 0);
      const itemsLength = get(context, "order.orderItems.length", 0);
      const badgeContent = itemsLength;

      return (
        <Badge
          badgeContent={badgeContent}
          color="secondary"
          variant={"standard"}
        >
          <TabIconWrapper>
            <NewOrderIcon />
          </TabIconWrapper>
        </Badge>
      );
    },

    onClick: (context) => {
      context.closeMobileAuthView();
      const { servingOptionType, branchId, orderItems } = context.order;
      if (servingOptionType && branchId) {
        navigateTo(setParams("/order", { servingOptionType, branchId }));
      } else {
        if (servingOptionType) {
          navigateTo(setParams("/find-location", { servingOptionType }));
        } else {
          navigateTo("/serving-options");
        }
      }
    },
    pathMatch: ["/serving-options", "/deal", "/order", "/find-location"],
  },
  {
    icon: () => (
      <TabIconWrapper>
        <ReorderIcon />
      </TabIconWrapper>
    ),
    label: "Reorder",
    onClick: (context) => {
      context.navigateToTargetOrLoginMobile("/reorder", LOGIN_TYPES.REORDER);
    },
    pathMatch: ["/reorder"],
  },
  {
    icon: () => (
      <TabIconWrapper>
        <ScanIcon />
      </TabIconWrapper>
    ),
    onClick: (context) => {
      context.navigateToTargetOrLoginMobile("/scan", LOGIN_TYPES.SCAN);
    },
    label: "Scan",
    pathMatch: ["/scan"],
  },
];

const pizzaTabs = set(
  set([...defaultTabs], "2", {
    ...defaultTabs[2],
    icon: () => (
      <TabIconWrapper>
        <NewOrderPizzaIcon/>
      </TabIconWrapper>
    ),
  }),
  "3",
  {
    ...defaultTabs[3],
    icon: () => (
      <TabIconWrapper>
        <PizzaReorderIcon />
      </TabIconWrapper>
    ),
  },
);

const iceCreamTabs = set([...defaultTabs], "2", {
  ...defaultTabs[2],
  icon: () => (
    <TabIconWrapper>
      <IceCreamIcon />
    </TabIconWrapper>
  ),
});

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    "aria-controls": `scrollable-prevent-tabpanel-${index}`,
  };
}
