import React from "react";
import { Provider } from "react-redux";
import forEach from "lodash/forEach";
import initReactFastclick from "react-fastclick";
import XHR from "i18next-xhr-backend";
import Backend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import i18n from "./src/i18n";
import { withPrefix } from "gatsby";
import wrapPage from "./wrap-page";

// window.isNativeApp = true;

const unregisterSW = () => {
  if (
    navigator &&
    navigator.serviceWorker &&
    navigator.serviceWorker.getRegistrations
  ) {
    navigator.serviceWorker
      .getRegistrations()
      .then((registrations) =>
        forEach(registrations, (registration) => registration.unregister()),
      );
  }
};

unregisterSW();

import configureStore from "./src/store/configureStore";

const initialState = {};

export const wrapRootElement = ({ element }) => {
  const store = configureStore(initialState, { loadLocalStorage: true });
  const ConnectedRootElement = <Provider store={store}>{element}</Provider>;
  return ConnectedRootElement;
};

export const onClientEntry = async () => {
  i18n.use(Backend).init({
    backend: {
      backends: [
        LocalStorageBackend, // primary
        XHR, // fallback
      ],
      backendOptions: [
        {
          expirationTime: 5 * 60 * 1000,
          prefix: `${process.env.GATSBY_BUSINESS_ID}_`,
        },
        { loadPath: withPrefix("locales/{{lng}}/{{ns}}.json") },
      ],
    },
  });
  initReactFastclick();

  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
};

export const wrapPageElement = wrapPage;

// exports.shouldUpdateScroll = ({ pathname, prevRouterProps }) => {
// 	if (pathname.startsWith('/order')) {
// 		console.log('noscroll top');
// 		false;
// 	}
//
// 	return true;
// };
