import _ from "lodash";
import { LOCATION_CHANGE } from "../../utils/constants";

const initialState = { onCloseTarget: "/" };

export default (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      if (!_.includes(action.payload.search, "onCloseTarget")) {
        switch (action.payload.pathname) {
          case "/signup":
          case "/login":
          case "/my-account":
          case "/verifyLoginCode":
            return {
              ...state,
              onCloseTarget: `/${action.payload.search}`,
            };
          // case '/payment':
          //   return {
          //     ...state,
          //     onCloseTarget: `/checkout${action.payload.search.replace('?', '***')}`
          //   };
          default:
            return {
              ...state,
              onCloseTarget: `${
                action.payload.pathname
              }${action.payload.search.replace("?", "***")}`,
            };
        }
      }
      return state;
    // case USER_ACTION_TYPE.VERIFY_CODE.SUCCESS:
    //   action.payload.backTo
    //   return state;

    default:
      return state;
  }
};
