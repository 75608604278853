import React from "react";
import {
  pick,
  get,
  noop,
  identity,
  defer,
  startsWith,
  times,
  isEmpty,
  find,
  map,
  trim,
  omitBy,
  isUndefined,
} from "lodash";
import classnames from "classnames";
import DebounceInput from "react-debounce-input";
import EmailValidator from "email-validator";
import validateCreditCard from "card-validator";
import isAfter from "date-fns/is_after";
import subYears from "date-fns/sub_years";
import validator from "validator";
import MobileDetect from "mobile-detect";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import ErrorXIcon from "../icons/ErrorX.svg";
import BirthdayIcon from "../icons/Birthday.svg";
import CellPhoneIcon from "../icons/CellPhone.svg";
import ProfileInputIcon from "../icons/ProfileInput.svg";
import EmailIcon from "../icons/Email.svg";
import KeyIcon from "../icons/Key.svg";
import ChevronDownIcon from "../icons/ChevronDown.svg";
import CreditCardIcon from "../icons/CreditCard.svg";
import CalendarIcon from "../icons/Calendar.svg";
import CreditCardCVVIcon from "../icons/CreditCardCVV.svg";
import isValidIsracard from "../../utils/isracard";

import {
  configureHostedField,
  setConfiguredFieldStyle,
} from "../../utils/hostedPaymentForm";

import styles from "./index.module.scss";

export class Input extends React.Component {
  state = {
    value: "",
    isValidAndUserChanged: false,
    userChanged: false,
    pastFirstBlur: Boolean(this.props.initialValue),
  };

  componentDidMount() {
    this.md = new MobileDetect(window.navigator.userAgent);
    if (this.props.focusOnMount) {
      this.inputElement.focus();
    }
    if (this.props.initialValue) {
      this.setState((state, props) => ({ value: props.initialValue }));
    }
    if (this.props.spreedlyHostedField && this.props.spreedlyScriptLoaded) {
      this.registerSpreedlyConfigurator();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.initialValue &&
      this.props.initialValue &&
      !this.state.value
    ) {
      this.setState({ value: this.props.initialValue });
    }

    if (
      this.props.spreedlyHostedField &&
      !prevProps.spreedlyScriptLoaded &&
      this.props.spreedlyScriptLoaded
    ) {
      this.registerSpreedlyConfigurator();
    }

    if (!prevProps.spreedlyInitialized && this.props.spreedlyInitialized) {
      this.configureHostedField();
    }
  }

  componentWillUnmount() {
    this.handleBlur = noop;
    if (typeof window === undefined) {
      console.log("WINDOW IS UNDEFINED");
    }

    if (this.md.os() === "iOS" && this.inputElement) {
      this.inputElement && this.inputElement.focus();
      this.inputElement && this.inputElement.blur();
    }
  }

  registerSpreedlyConfigurator = () => {
    window.Spreedly.on("ready", this.configureHostedField);
  };

  configureHostedField = () => {
    const { appStyles, spreedlyFieldKey, onValid, onError } = this.props;
    const inputStyle = {
      fontFamily: `${
        appStyles.bodyFont ? `${appStyles.bodyFont}, ` : ""
      }Helvetica, Arial, sans-serif`,
      ...(appStyles.bodyFontWeight && {
        fontWeight: appStyles.bodyFontWeight,
      }),
      height: "30px",
      width: "100%",
      marginLeft: "12px",
      fontSize: appStyles.baseFontSize || "16px",
      ...this.props.style,
      ..._.pick(appStyles.Input, [
        "fontSize",
        "fontWeight",
        "fontFamily",
        "color",
      ]),
    };
    const errorInputStyle = {
      ...inputStyle,
      color: "#ff0012",
    };
    window.Spreedly.on("validation", (inputProperties) => {
      if (inputProperties[this.props.spreedlyValidationField]) {
        this.setState({ error: null });
        setConfiguredFieldStyle({ spreedlyFieldKey, style: inputStyle });
      } else {
        this.setState({
          pastFirstBlur: true,
          forceFocus: true,
          error: this.props.errorMessage,
        });
        setConfiguredFieldStyle({ spreedlyFieldKey, style: errorInputStyle });
      }
    });
    window.Spreedly.on(
      "fieldEvent",
      (name, type, activeEl, inputProperties) => {
        if (name === this.props.spreedlyFieldKey) {
          if (type === "input") {
            if (inputProperties[this.props.spreedlyValidationField]) {
              this.setState({ userChanged: true, error: false });
              setConfiguredFieldStyle({ spreedlyFieldKey, style: inputStyle });
            } else {
              this.setState({
                userChanged: true,
                error: this.props.errorMessage,
              });
              setConfiguredFieldStyle({
                spreedlyFieldKey,
                style: errorInputStyle,
              });
            }
          } else if (type === "blur") {
            this.setState({ pastFirstBlur: true, forceFocus: false });
          } else if (type === "focus") {
            this.setState({ forceFocus: true });
          }
        }
      },
    );
    window.Spreedly.on("errors", (errors) => {
      const firstRelevantError = find(errors, { attribute: spreedlyFieldKey });
      if (firstRelevantError) {
        this.setState({
          userChanged: true,
          error: this.props.errorMessage,
          pastFirstBlur: true,
        });
        setConfiguredFieldStyle({ spreedlyFieldKey, style: errorInputStyle });
      }
    });
    configureHostedField({
      spreedlyFieldKey,
      ...pick(this.props, ["placeholder", "type"]),
      style: inputStyle,
    });
  };

  isValid = this.props.validator || (() => true);

  handleChange = (e) => {
    const { onChange = noop, notifyAny } = this.props;
    this.setState({ value: e.target.value, userChanged: true }, () => {
      if (this.state.pastFirstBlur) {
        this.validate();
        if (notifyAny) {
          onChange(this.inputElement.value);
        }
      } else if (this.isValid(this.inputElement.value)) {
        onChange(this.inputElement.value);
      } else if (notifyAny) {
        onChange(this.inputElement.value);
      }
    });
  };

  handleBlur = (e) => {
    e.persist();
    this.setState({ pastFirstBlur: true }, () => this.handleChange(e));
  };

  clearInput = () => {
    if (this.inputElement) {
      this.inputElement.blur();
      defer(() => {
        this.setState({ value: "", userChanged: false, error: null });
        if (this.inputElement) {
          this.inputElement.value = "";
        }
      });
    }
  };

  validate = (callback = noop) => {
    const {
      onValid = noop,
      onError = noop,
      onChange = noop,
      T = identity,
      inputSanitizer: sanitizeInput = identity,
      spreedlyHostedField,
    } = this.props;
    if (spreedlyHostedField) {
      //skip validation called
    } else {
      const sanitziedInput = sanitizeInput(this.inputElement.value);

      if (this.isValid(sanitziedInput)) {
        this.setState({ error: null }, () => {
          onValid(sanitziedInput);
          onChange(sanitziedInput);
          callback(null, sanitziedInput);
        });
      } else {
        const error = T(this.props.errorMessage) || "error";
        this.setState({ error }, () => {
          onError(error);
          callback(error, sanitziedInput);
        });
      }
    }
  };

  render() {
    const {
      classNames,
      placeholder,
      inputRef,
      appStyles = {},
      refEl,
      T = identity,
      type,
      name,
      disabled,
      element,
      inputSanitizer = identity,
      wrapperStyle,
      style,
      step,
      iconComponent: IconComponent,
      children,
      autoComplete,
      customInputAttributes,
      withButtons,
      large,
      value: propsValue,
      noCheckmark,
      spreedlyHostedField,
      spreedlyHostedFieldAttributes,
      hidden,
      ...inputProps
    } = this.props;

    const value = this.props.value || this.state.value;
    const _isValidInput = spreedlyHostedField
      ? this.state.userChanged && !this.state.error
      : this.isValid(value);

    const isUserValidInput = this.state.userChanged && _isValidInput;

    const displayValidation = spreedlyHostedField
      ? this.state.pastFirstBlur || !this.state.error
      : true;

    return (
      <div
        className={classnames(
          styles.InputWithErrorContainer,
          this.state.error && displayValidation && styles.HasError,
          appStyles.rtl && styles.RTL,
          withButtons && styles.WithButtons,
          large && styles.LargeInput,
          noCheckmark && styles.WithoutCheckmark,
          classNames,
        )}
        style={{
          ...(appStyles.inputOnCardTextColor && {
            color: appStyles.inputOnCardTextColor,
          }),
          ...wrapperStyle,
          ...(appStyles.inputBorderRadius && {
            borderRadius: appStyles.inputBorderRadius,
          }),
          ...appStyles.Input,
          ...omitBy(style, isUndefined),
          ...(this.state.error &&
            displayValidation && { background: "transparent" }),
        }}
        hidden={hidden}
      >
        <span
          className={styles.InputWrapper}
          style={{
            ...(appStyles.inputBorderRadius && {
              borderRadius: appStyles.inputBorderRadius,
            }),
            ...(!disabled &&
              isUserValidInput &&
              displayValidation && {
                border: `1px solid ${appStyles.inputValidColor}`,
              }),
          }}
        >
          {isUserValidInput &&
          !disabled &&
          !noCheckmark &&
          displayValidation ? (
            <span
              style={{ color: appStyles.inputValidColor }}
              className={classnames(
                styles.AnimatedValidCheckmark,
                appStyles.rtl && styles.RTL,
              )}
            >
              {<span className={classnames(styles.Checkmark, styles.Draw)} />}
            </span>
          ) : (
            IconComponent && (
              <IconComponent
                className={classnames(styles.InputIcon, "noselect")}
              />
            )
          )}
          {this.props.alternativePlaceholder && !value && (
            <span className={styles.AlternativePlaceholder}>
              {this.props.alternativePlaceholder}
            </span>
          )}
          {spreedlyHostedField ? (
            <div
              {...spreedlyHostedFieldAttributes}
              style={{
                width: "100%",
                height: 28,
                display: "flex",
                alignItems: "center",
              }}
            />
          ) : (
            <DebounceInput
              style={style}
              element={element}
              name={name}
              inputRef={(inputElement) => {
                this.inputElement = inputElement;
              }}
              onBlur={this.handleBlur}
              disabled={disabled}
              value={value}
              step={step}
              onChange={this.handleChange}
              debounceTimeout={600}
              autoComplete={autoComplete}
              ref={inputRef}
              placeholder={T(placeholder)}
              type={type}
              {...customInputAttributes}
            />
          )}

          {children}
        </span>
        {this.state.error && displayValidation && (
          <span
            role="alert"
            aria-atomic="true"
            aria-live="assertive"
            className={classnames(
              styles.Error,
              appStyles.rtl && styles.RTL,
              large && styles.LargeError,
            )}
          >
            <ErrorXIcon className="noselect" />
            {T(this.state.error)}
          </span>
        )}
      </div>
    );
  }
}

export class PhoneInput extends React.Component {
  render() {
    const cleanPhoneInput = (input = "") =>
      input.replace(/\.|-|\s/g, "").trim();

    return (
      <Input
        {...this.props}
        ref={this.props.refEl}
        errorMessage="Please enter a valid mobile phone number."
        validator={(input) => {
          const phoneInput = cleanPhoneInput(input);
          if (isEmpty(phoneInput)) {
            return false;
          }

          if (this.props.disabled) {
            return true;
          }
          if (startsWith(phoneInput, "972")) {
            /* allow testing case for Israel numbers in all locales */
            return true;
          }

          if (this.props.locale === "de-CH") {
            const phoneNumber = parsePhoneNumberFromString(phoneInput, "CH");

            return Boolean(phoneNumber) && phoneNumber.isValid();
          }

          if (get(this.props.appStyles, "noPhoneNumbersValidation")) {
            return /(^\d{6,}$)/.test(phoneInput);
          }

          // default case:
          try {
            if (this.props.locale === "he-IL") {
              return (
                validator.isMobilePhone(phoneInput, this.props.locale) ||
                validator.isMobilePhone(phoneInput, "en-US")
              );
            }
            // validator.isMobilePhone(phoneInput, this.props.locale)
            return validator.isMobilePhone(phoneInput, this.props.locale);
          } catch (e) {
            console.error(
              e,
              "No phone number validation has been made. returning true.",
              "Locale defined: ",
              this.props.locale,
            );
            return true;
          }
        }}
        inputSanitizer={cleanPhoneInput}
        iconComponent={CellPhoneIcon}
        name="phone"
        autoComplete={this.props.autoComplete || "tel"}
        type="tel"
      />
    );
  }
}

export class CodeInput extends React.Component {
  render() {
    return (
      <Input
        {...this.props}
        ref={this.props.refEl}
        errorMessage="Please enter the code you received"
        validator={(input = "") => input.length > 4}
        inputSanitizer={trim}
        iconComponent={KeyIcon}
        autoComplete="one-time-code"
        type="tel"
      />
    );
  }
}

export class FullNameInput extends React.Component {
  render() {
    return (
      <Input
        {...this.props}
        ref={this.props.refEl}
        errorMessage="Please enter a full name"
        validator={(input = "") => {
          if (input.length > 254) {
            return false;
          }
          return this.props.allowAnyName
            ? input.length > 2
            : input.match(
                /[A-Za-z\u0590-\u05fe\-_.\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF]{2,}(\s[A-Za-z\u0590-\u05fe\-_.\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF]{1,}){1,}/,
              );
        }}
        iconComponent={!this.props.noIcon && ProfileInputIcon}
        type="text"
        name="fullname"
      />
    );
  }
}

export class EmailInput extends React.Component {
  render() {
    return (
      <Input
        {...this.props}
        ref={this.props.refEl}
        errorMessage="Please enter a valid Email Address"
        validator={(input = "") =>
          (this.props.notRequired && isEmpty(input)) ||
          (input.match(/\.(?:[a-zA-Z](?!\.))+$/) &&
            EmailValidator.validate(input))
        }
        iconComponent={EmailIcon}
        type="email"
        name={
          this.props.name || this.props.autoComplete
            ? this.props.autoComplete + "email"
            : "email"
        }
      />
    );
  }
}

export class DateInput extends React.Component {
  render() {
    return (
      <Input
        {...this.props}
        ref={this.props.refEl}
        errorMessage={this.props.errorMessage}
        validator={this.props.validator}
        iconComponent={this.props.iconComponent}
        alternativePlaceholder={this.props.placeholder}
        type={this.props.type || "date"}
      />
    );
  }
}

export class BirthdayInput extends React.Component {
  render() {
    return (
      <DateInput
        {...this.props}
        errorMessage="Please enter your birthday. You must be 13 years old or older to use this app."
        validator={(input) => {
          if (isEmpty(input)) {
            return true;
          }

          return isAfter(subYears(new Date(), 13), new Date(input));
        }}
        iconComponent={BirthdayIcon}
      />
    );
  }
}

export class TextInput extends React.Component {
  render() {
    return (
      <Input
        {...this.props}
        ref={this.props.refEl}
        validator={
          this.props.validator ? this.props.validator : (input) => true
        }
        classNames={classnames(
          styles.TextInput,
          get(this.props, "appStyles.rtl") && styles.RTL,
        )}
      />
    );
  }
}

export class HouseNumberInput extends React.Component {
  render() {
    return (
      <Input
        {...this.props}
        ref={this.props.refEl}
        name="house_number"
        type="tel"
        validator={(input) => /^\d+$/.test(input.trim())}
        classNames={styles.HouseNumberInput}
        errorMessage="Enter digits only"
        noCheckmark
      />
    );
  }
}

export class Select extends React.Component {
  state = { selected: null, userChanged: false };

  componentWillUnmount() {
    this.handleBlur = noop;
    if (typeof window === undefined) {
      console.log("WINDOW IS UNDEFINED");
    }
    const md = new MobileDetect(window.navigator.userAgent);
    if (md.os() === "iOS") {
      this.inputElement && this.inputElement.focus();
      this.inputElement && this.inputElement.blur();
    }
  }

  isValid = () => true;

  onChange = (e) => {
    e.preventDefault();
    const {
      onValid = noop,
      onError = noop,
      onChange = noop,
      T = noop,
    } = this.props;
    const value = e.target.value;

    this.setState({ selected: value, userChanged: true }, () => {
      if (this.isValid()) {
        onValid(pick(this.state, ["selected"]));
      } else {
        onError(T("Please select one of the options"));
      }
      onChange(this.state.selected);
    });
  };

  render() {
    const {
      appStyles = {},
      backgroundColor,
      placeholder,
      inputValidColor,
      iconComponent: IconComponent,
      noCheckmark,
    } = this.props;
    const isSelected = this.props.value || this.state.selected;
    const style = omitBy(this.props.style, isUndefined);

    return this.props.loading ? (
      <span
        style={{
          ...style,
          backgroundColor,
        }}
        className={classnames(
          this.props.classNames,
          styles.InputWrapper,
          this.props.rtl && styles.RTL,
        )}
      >
        <div
          style={{
            color: this.props.loaderColor,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transform: "translateY(calc(-25% + 8px))",
            fontSize: "0.375rem",
          }}
        >
          <span className={styles.SelectLoader} />
        </div>
      </span>
    ) : (
      <span
        style={{
          ...style,
          borderRadius: appStyles.inputBorderRadius,
          backgroundColor,
          ...(isSelected &&
            inputValidColor && { border: `1px solid ${inputValidColor}` }),
          ...appStyles.Input,
        }}
        className={classnames(
          this.props.classNames,
          styles.InputWrapper,
          this.props.rtl && styles.RTL,
          noCheckmark && styles.WithoutCheckmark,
        )}
      >
        {!noCheckmark && isSelected ? (
          <span
            style={{ color: inputValidColor }}
            className={classnames(
              styles.AnimatedValidCheckmark,
              this.props.rtl && styles.RTL,
            )}
          >
            {<span className={classnames(styles.Checkmark, styles.Draw)} />}
          </span>
        ) : (
          IconComponent && (
            <IconComponent
              className={classnames(
                styles.SelectIcon,
                this.props.rtl && styles.RTL,
              )}
            />
          )
        )}
        <select
          ref={(inputElement) => {
            this.inputElement = inputElement;
          }}
          onChange={this.onChange}
          value={isSelected ? this.props.value : ""}
          style={{ WebkitAppearance: "none" }}
        >
          {placeholder && (
            <option
              value="none"
              style={this.props.value && { display: "none" }}
            >
              {placeholder}
            </option>
          )}
          {map(this.props.options, (option, index) => (
            <option
              key={index}
              value={option.value}
              {...!isSelected && { selected: false }}
            >
              {option.label}
            </option>
          ))}
        </select>
        <ChevronDownIcon
          className={classnames(
            styles.SelectChevronDownIcon,
            this.props.rtl && styles.RTL,
          )}
        />
      </span>
    );
  }
}

export class CreditCardNumber extends React.Component {
  render() {
    return (
      <Input
        {...this.props}
        ref={this.props.refEl}
        errorMessage="Please enter a valid credit card number"
        validator={(input) => {
          const unacceptedCardPrefixes = this.props.unacceptedCardPrefixes;
          if (
            !isEmpty(unacceptedCardPrefixes) &&
            find(unacceptedCardPrefixes, (prefix) => startsWith(input, prefix))
          ) {
            return false;
          }
          return (
            validateCreditCard.number(input).isValid || isValidIsracard(input)
          );
        }}
        type="tel"
        placeholder="•••• •••• •••• ••••"
        inputmode="numeric"
        autoComplete={this.props.autoComplete || "cc-number"}
        spreedlyFieldKey="number"
        spreedlyHostedFieldAttributes={{ id: "spreedly-number" }}
        iconComponent={CreditCardIcon}
        spreedlyValidationField="validNumber"
      />
    );
  }
}

export class CreditCardCVV extends React.Component {
  render() {
    return (
      <Input
        {...this.props}
        wrapperStyle={!this.props.noWrapperStyle && { width: "60%" }}
        ref={this.props.refEl}
        errorMessage="Please enter a valid credit card CVV"
        validator={(input) =>
          input.length > 2 && input.length < 5 && input.match(/^[0-9]+$/)
        }
        type="tel"
        placeholder="Enter CVV"
        inputmode="numeric"
        autoComplete={this.props.autoComplete || "cc-cvv"}
        spreedlyFieldKey="cvv"
        spreedlyHostedFieldAttributes={{ id: "spreedly-cvv" }}
        iconComponent={CreditCardCVVIcon}
        spreedlyValidationField="validCvv"
      />
    );
  }
}

export class ZipCode extends React.Component {
  render() {
    return (
      <Input
        {...this.props}
        wrapperStyle={!this.props.noWrapperStyle && { width: "40%" }}
        ref={this.props.refEl}
        errorMessage="Please enter a valid billing address ZIP code"
        inputSanitizer={(input) => input.trim()}
        validator={(input) =>
          ((input.length === 5 || input.length === 9) && /^\d+$/.test(input)) ||
          (input.length === 6 && /^[a-zA-Z0-9_]*$/.test(input))
        }
        type="tel"
        placeholder="#####"
        inputmode="numeric"
        autoComplete={this.props.autoComplete || "cc-zipCode"}
      />
    );
  }
}

export class HiddenInput extends React.Component {
  render() {
    return (
      <Input {...this.props} type="hidden" ref={this.props.refEl} hidden />
    );
  }
}

const getCurrentYear = () => new Date().getYear() + 1900;

const getCreditCardYearsOptions = () => {
  const currentYear = getCurrentYear();
  return map(times(10, (index) => currentYear + index), (year) => ({
    label: year,
    value: year,
  }));
};

const CardExpiryDateError = "Please enter a valid card expiration date";

export class CreditCardExpiry extends React.Component {
  state = {
    month: null,
    year: null,
  };

  errorMessage =
    this.props.errorMessage || this.props.T
      ? this.props.T(CardExpiryDateError)
      : CardExpiryDateError;

  onChange = (key) => (value) => {
    const {
      onValid = noop,
      onError = noop,
      onChange = noop,
      T = noop,
    } = this.props;

    this.setState({ [key]: value }, () => {
      if (this.isValid()) {
        onValid(pick(this.state, ["month", "year"]));
        this.setState({ error: null });
      } else {
        onError(this.errorMessage);
        this.setState({ error: this.errorMessage });
      }
    });
  };

  isValid = () => {
    if (this.state.month && this.state.year) {
      const expiryDate = new Date(this.state.year, this.state.month, 1); // 12/2018 -> 1/13/2018 -> 1/1/2019 0:00:00
      if (isAfter(expiryDate, new Date())) {
        return true;
      }
    }
    return false;
  };

  validate = (callback = noop) => {
    const {
      onValid = noop,
      onError = noop,
      onChange = noop,
      T = noop,
    } = this.props;

    if (this.isValid()) {
      onValid(pick(this.state, ["month", "year"]));
      this.setState({ error: null });
      callback(null, pick(this.state, ["month", "year"]));
    } else {
      onError(this.errorMessage);
      this.setState({ error: this.errorMessage });
      callback(this.errorMessage);
    }
  };

  render() {
    const { T = noop, appStyles = {} } = this.props;
    const selectStyles = pick(appStyles.Input, [
      "letterSpacing",
      "fontFamily",
      "fontStyle",
      "fontWeight",
      "color",
    ]);
    return (
      <div
        style={{ marginBottom: 16 }}
        className={classnames(this.state.error && styles.HasError)}
      >
        <div
          style={{
            display: "flex",
            ...(appStyles.rtl && { flexDirection: "row-reverse" }),
          }}
        >
          <Select
            placeholder={T("Month")}
            options={times(12, (index) => ({
              label: index + 1,
              value: index + 1,
            }))}
            onChange={this.onChange("month")}
            rtl={appStyles.rtl}
            style={{
              ...selectStyles,
              borderTopLeftRadius: appStyles.inputBorderRadius,
              borderBottomLeftRadius: appStyles.inputBorderRadius,
            }}
            value={this.state.month}
            backgroundColor={appStyles.inputOnCardBackgroundColor}
            inputValidColor={appStyles.inputValidColor}
            iconComponent={CalendarIcon}
          />
          <Select
            placeholder={T("Year")}
            options={getCreditCardYearsOptions()}
            onChange={this.onChange("year")}
            value={this.state.year}
            rtl={appStyles.rtl}
            style={{
              ...selectStyles,
              borderTopRightRadius: appStyles.inputBorderRadius,
              borderBottomRightRadius: appStyles.inputBorderRadius,
            }}
            backgroundColor={appStyles.inputOnCardBackgroundColor}
            inputValidColor={appStyles.inputValidColor}
            iconComponent={CalendarIcon}
          />
        </div>
        {this.state.error && (
          <div className={styles.Error}>
            <ErrorXIcon className="noselect" />
            {T(this.state.error)}
          </div>
        )}
      </div>
    );
  }
}

export class InputGroup extends React.Component {
  render() {
    const { children, focusFirst, appStyles = {}, T, style } = this.props;

    return (
      <div
        className={classnames(
          styles.InputGroup,
          this.props.tight && styles.Tight,
          appStyles.rtl && styles.RTL,
        )}
        style={style}
      >
        {React.Children.map(
          children,
          (child) =>
            child &&
            React.cloneElement(child, { ...child.props, appStyles, T }),
        )}
      </div>
    );
  }
}
