import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dialog } from "@material-ui/core";
import { get } from "lodash";
import withTranslation from "../../hocs/withTranslation";
import { getPrivacyConsentOptions, getStyles } from "../../store/selectors";
import {
  closePrivacyConsent,
  loadPrivacyConsentDetails,
  sendPrivacyConsent,
  setProfileInSignupFlowMode,
} from "../../store/user/actions";
import { navigateTo } from "../../utils/location";
import { isNewUser } from "../../utils/user";
import AppContainer from "../AppContainer";
import Button from "../Button";
import Card from "../Card";
import Loader from "../Loader";
import RichText from "../rich-text";
import styles from "./index.module.scss";

function ConsentView({ T, modalMode, ...props }) {
  const dispatch = useDispatch();

  const {
    user: {
      consentState: { sending, sent },
      privacyConsentDetails: { data: privacyConsentDetailsData },
      showPrivacyConsent,
      loggedIn,
    },
    app: { loginType },
  } = useSelector(({ app, user }) => ({ app, user }));
  const app = useSelector(({ app }) => app);
  const user = useSelector(({ user }) => user);
  const privacyConsentDetails = useSelector(
    ({ user }) => user.privacyConsentDetails,
  );

  const appStyles = getStyles({ app }, props);
  const privacyConsentOptions = getPrivacyConsentOptions({ user });

  const [chosenOptions, setChosenOptions] = useState([]);

  //---spy on prev value for ConsentSentState---
  const prevConsentSentState = useRef();

  useEffect(() => {
    prevConsentSentState.current = sent;
  }, [sent]);
  //---end---

  useEffect(() => {
    loggedIn && dispatch(loadPrivacyConsentDetails());
  }, [loggedIn]);

  //--- TODO check this code if it's needed---
  useEffect(() => {
    if (
      !prevConsentSentState.current &&
      sent &&
      get(user, "consentState.data.status")
    ) {
      if (appStyles.showProfileOnSignup && isNewUser(user)) {
        dispatch(setProfileInSignupFlowMode());
        navigateTo("/profile");
        return;
      }

      loginType && navigateTo(`/${loginType}`);
    }
  }, [sent]);
  //---end---

  const onSubmit = () => {
    //---select all available options---
    if (chosenOptions.length !== privacyConsentOptions.length) {
      setChosenOptions(Object.keys(privacyConsentOptions).map(Number));
      return;
    }
    //---end---
    dispatch(sendPrivacyConsent(true));
  };

  const onDecline = (_, reason) => {
    //---prevents closing modal on click outside this modal---
    if (reason === "backdropClick") {
      return;
    }
    //---end---
    dispatch(sendPrivacyConsent(false));
    dispatch(closePrivacyConsent());
  };

  const loadingConsentView = useMemo(
    () => (
      <AppContainer.Content tightBottom appStyles={appStyles}>
        <AppContainer.CenteredColumn>
          <Loader appStyles={appStyles} classNames={styles.Loader} />
          <strong>{T("Loading Privacy Consent...")}</strong>
        </AppContainer.CenteredColumn>
      </AppContainer.Content>
    ),
    [appStyles, styles],
  );

  const consentViewError = useMemo(
    () => (
      <AppContainer.Content tightBottom appStyles={appStyles}>
        <AppContainer.CenteredColumn>
          <h4>{T("Could not load Privacy Consent")}</h4>
          <p style={{ color: appStyles.accentColor }}>
            {T("We're experiencing network problems...")}
          </p>
          <Button
            appStyles={appStyles}
            onClick={() => dispatch(loadPrivacyConsentDetails())}
          >
            {T("Try Again")}
          </Button>
        </AppContainer.CenteredColumn>
      </AppContainer.Content>
    ),
    [appStyles],
  );

  let consentViewComponent = useMemo(
    () =>
      !privacyConsentDetailsData ? null : (
        <AppContainer.Content
          appStyles={appStyles}
          modalMode={modalMode}
          animate
        >
          <AppContainer.CenteredColumn>
            <h2 className={styles.Title} style={appStyles.Signup}>
              {T("Privacy Consent")}
            </h2>
            <p className={styles.Consent}>
              {privacyConsentDetailsData.heading}
            </p>
            <p className={styles.Consent}>
              {privacyConsentDetailsData.subheading}
            </p>
          </AppContainer.CenteredColumn>
          <Card appStyles={appStyles}>
            <Card.Content classNames={styles.ConsentSection}>
              <Card.Content.CheckboxGroup
                name={T("")}
                checked={chosenOptions}
                onChange={(chosenOptions) => setChosenOptions(chosenOptions)}
                wrap={false}
                appStyles={appStyles}
              >
                {privacyConsentOptions.map((option, indx) => (
                  <Card.Content.CheckboxOption
                    iconSize={16}
                    iconInnerSize={16}
                    pointColor={appStyles.actionColor}
                    value={indx}
                    vertical
                  >
                    <RichText>{option}</RichText>
                  </Card.Content.CheckboxOption>
                ))}
              </Card.Content.CheckboxGroup>
            </Card.Content>
          </Card>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 16,
            }}
          >
            <Button
              onClick={onSubmit}
              appStyles={appStyles}
              centered
              loading={sending}
              completed={sent}
              style={{
                minWidth: 100,
                ...(chosenOptions.length < privacyConsentOptions.length && {
                  backgroundColor: appStyles.accentColor,
                }),
              }}
              disabled
            >
              {chosenOptions.length == privacyConsentOptions.length
                ? T("I Agree")
                : privacyConsentOptions.length > 1
                ? T("Check all consents")
                : T("Check consent")}
            </Button>
            <p>{T("or")}</p>
            <Button
              linkStyle
              appStyles={appStyles}
              style={{ minWidth: 100, marginTop: 16 }}
              centered
              onClick={onDecline}
            >
              <small>{T("Use it as Guest without logging in")}</small>
            </Button>
          </div>
        </AppContainer.Content>
      ),
    [
      appStyles,
      chosenOptions,
      privacyConsentOptions,
      privacyConsentDetailsData,
    ],
  );

  if (privacyConsentDetails?.loading) {
    consentViewComponent = loadingConsentView;
  }

  if (privacyConsentDetails?.error) {
    consentViewComponent = consentViewError;
  }

  return modalMode ? (
    <Dialog
      open={showPrivacyConsent}
      onClose={closePrivacyConsent}
      scroll="body"
    >
      {consentViewComponent}
    </Dialog>
  ) : (
    consentViewComponent
  );
}

export default withTranslation(({ T, ...props }) => (
  <ConsentView T={T} {...props} />
));
