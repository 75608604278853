import _ from "lodash";
import * as ACTION_TYPE from "./constants";

const initialState = {
  common: {},
  scrollPosition: 0,
  addToOrderToTarget: "/order",
};

export default (state = initialState, action) => {
  const initUserIdItemConfigurationIfNeeded = () => {
    if (!state[action.payload.userId]) {
      _.set(state, action.payload.userId, {});
    }
    if (!state[action.payload.userId][action.payload.id]) {
      _.set(state, `${action.payload.userId}.${action.payload.id}`, {});
    }
  };

  switch (action.type) {
    case ACTION_TYPE.SET_MENU_ITEM_CONFIGURATION:
      initUserIdItemConfigurationIfNeeded();
      return {
        ...state,
        [action.payload.userId]: {
          ...state[action.payload.userId],
          [action.payload.id]: {
            ...state[action.payload.userId][action.payload.id],
            ...action.payload.menuItemConfiguration,
          },
        },
      };

    case ACTION_TYPE.SET_MENU_ITEM_COMPONENT:
      // this is the configured orderItemComponent index which is not the same as
      // the one in the ssr context menuItemOrdeData orderItem Component index.
      initUserIdItemConfigurationIfNeeded();

      const orderItemComponentConfiguration = _.pick(action.payload, [
        "title",
        "chosenOrderItemComponentOptions",
      ]);

      if (
        Array.isArray(
          state[action.payload.userId][action.payload.id].orderItemComponents,
        )
      ) {
        const componentIndex = _.findIndex(
          state[action.payload.userId][action.payload.id].orderItemComponents,
          { title: action.payload.title },
        );

        return _.clone(
          _.set(
            state,
            `${action.payload.userId}[${
              action.payload.id
            }].orderItemComponents[${
              componentIndex > -1
                ? componentIndex
                : state[action.payload.userId][action.payload.id]
                    .orderItemComponents.length
            }]`,
            orderItemComponentConfiguration,
          ),
        );
      }

      return _.clone(
        _.set(
          state,
          `${action.payload.userId}[${action.payload.id}].orderItemComponents`,
          [orderItemComponentConfiguration],
        ),
      );

    case ACTION_TYPE.SET_MENU_ITEM_TOPPING:
      return _.clone(
        _.set(
          state,
          [
            action.payload.userId,
            action.payload.id,
            "toppings",
            `${action.payload.categoryTitle}_${action.payload.toppingTitle}`,
          ],
          _.pick(action.payload, [
            "position",
            "weight",
            "multiplierId",
            action.payload.weight && "chosenTime",
          ]),
        ),
      );

    case ACTION_TYPE.SET_MENU_ITEM_SIZE:
      return _.clone(
        _.set(
          state,
          `${action.payload.userId}[${action.payload.id}].selectedMenuSizeId`,
          action.payload.selectedMenuSizeId,
        ),
      );

    case ACTION_TYPE.RESET_MENU_ITEM_CONFIGURATION:
      return _.clone(
        _.set(
          {
            ...state,
            [action.payload.userId]: _.omit(
              state[action.payload.userId],
              action.payload.id,
            ),
          },
          `${action.payload.userId}[${action.payload.id}]`,
          action.payload.initialConfiguration,
        ),
      );

    case ACTION_TYPE.SET_MENU_SCROLL_POSITION:
      return {
        ...state,
        scrollPosition: action.payload.position,
      };

    default:
      return state;
  }
};
